import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";

import messagingUtils from "../../../utils/messagingUtils";

import "./ChatUserListItem.scss";

ChatUserListItem.propTypes = {
  user: PropTypes.any.isRequired,
  selected: PropTypes.bool,
  lastMessage: PropTypes.any,
  unreadMessage: PropTypes.number,
};

export default function ChatUserListItem({user, selected = false, lastMessage, unreadMessage = 0, ...attributes}) {
  const containerClassNames = classnames("preview", {
    "selected": selected,
  });

  const onlineClassName = classnames({
    "online": user.isOnline,
    "offline": !user.isOnline,
  });

  let profileStyle = user.image !== null ? { backgroundImage: `url(${user.image})` } : {};

  let date;

  if (lastMessage) {
    let messageDate = moment(lastMessage.createdAt);

    let today = moment();

    if (today.isSame(messageDate, "d")) {
      date = moment(lastMessage.createdAt).format("HH:mm");
    } else {
      date = moment(lastMessage.createdAt).format("DD-MM-YYYY");
    }
  }

  let message;
  if (lastMessage && lastMessage.message && lastMessage.message.indexOf("file_path") > 0) {
    const fileObject = JSON.parse(lastMessage.message);
    message = fileObject.file_name.substr(0, 50);
  } else {
    message = lastMessage ? messagingUtils.formatMessage(lastMessage.message).substr(0, 50) : null;
  }

  return (
    <div className={containerClassNames} {...attributes}>
      <div className={onlineClassName}>
        <div className="preview-user-image" style={profileStyle}/>
      </div>

      <div className="preview-user">
        <div className="username">{`${user.firstname} ${user.lastname}`}</div>
        {user.city && <div><i className="fas fa-map-marker-alt mr-1"/>{user.city.name_tr}</div>}
        {user.profession && <div><i className="fas fa-briefcase brown mr-1"/>{user.profession.name}</div>}
      </div>
      <div className="preview-message">
        {message &&
          <Fragment>
            <div>{date}</div>
            <div className="message">{message}</div>
          </Fragment>
        }
      </div>
      {unreadMessage > 0 && <div className="unread">{unreadMessage}</div>}
    </div>
  );
}

import React, {Fragment, useEffect, useState, useMemo} from "react";
import {connect} from "react-redux";
import {Row, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";

import ChatMessageListItem from "./ChatMessageListItem";
import ChatUserListItem from "./ChatUserListItem";
import LoadingIndicator from "../../parts/loading-indicator/LoadingIndicator";
import {RoutePaths} from "../../../constants";
import actions from "../../../redux/actions";
import sendIcon from "../../../assets/images/send-icon.png";

import "./Chat.scss";


Chat.propTypes = {};

function Chat({messages, getMessagesPending, getMessagesSuccess, getUserMessagesPending, getUserMessagesSuccess, user, dispatch, match, history}) {
  const [message, setMessage] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  let selectedUser;

  let messageContainerRef = React.createRef();

  const { id } = match.params;
  if (getMessagesSuccess && id) {
    selectedUser = messages.users.find(u => u.id === parseInt(id, 10));
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    dispatch(actions.getMessages(false));
    updateWindowDimensions();

    if (window.innerWidth < 768 && id) {
      setShowDetail(true);
    }

    return () => {
      // componentWillUnmount
      dispatch(actions.resetSelectedUser());
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  useEffect(() =>{
    if (selectedUser) {
      dispatch(actions.getMessagesOfUser(selectedUser.id));
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser && messages && messages[selectedUser.id]) {
      messages[selectedUser.id].map(message => {
        if (
          (message.from_user_id === user.id && message.from_is_read !== 1) ||
          (message.to_user_id === user.id && message.to_is_read !== 1)
        ) {
          dispatch(actions.patchMessage(message, user));
        }
      });

      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }
  }, [messages.randomize, selectedUser]);

  function handleMessageChange(event) {
    event.preventDefault();
    setMessage(event.target.value);
  }

  function handleInputKeyDown(event) {
    if (event.key === "Enter") {
      handleSendMessageClick();
    }
  }

  function handleSendMessageClick() {
    if (selectedUser && !selectedUser.hasBlockedMe && !selectedUser.isBlocked && message.trim() !== "") {
      dispatch(actions.sendMessage(message.trim(), selectedUser.id));
      setMessage("");
    }
  }

  function handleUserSelect(event) {
    event.preventDefault();

    if (isMobile) {
      setShowDetail(true);
    }

    const selectedUserId = parseInt(event.currentTarget.dataset.userId, 10);
    history.push(`/chat/${selectedUserId}`);
  }

  function handleDeleteMessages(event) {
    event.preventDefault();

    if (selectedUser) {
      dispatch(actions.deleteMessages(selectedUser.id, user.id));
    }

    if (isMobile) {
      setShowDetail(false);
    }
  }

  const previewsClassNames = classnames({
    "previews": true,
    "d-none": !isMobile || history.location.pathname === "/chat" ? false : showDetail
  });

  const chatContainerClassNames = classnames({
    "chat-container": true,
    "d-none": !isMobile || history.location.pathname === "/chat" ? false : !showDetail
  });

  function showPreviewHandler(){
    if(!isMobile) return;

    setShowDetail(false);
  }

  function updateWindowDimensions(){
    if(window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  let profileStyle = selectedUser && selectedUser.image !== null ? { backgroundImage: `url(${selectedUser.image})` } : {};

  const onlineClassName = classnames({
    "online": selectedUser ? selectedUser.isOnline : false,
    "offline": selectedUser ? !selectedUser.isOnline : true,
  });

  const sortedUsers = messages.users ? messages.users.sort((a, b) => {
    if (messages[a.id].length === 0 || messages[b.id].length === 0) {
      return 0;
    }

    const lastMessageA = messages[a.id][messages[a.id].length - 1];
    const lastMessageB = messages[b.id][messages[b.id].length - 1];

    return moment(lastMessageB.createdAt).valueOf() - moment(lastMessageA.createdAt).valueOf();
  }) : [];

  const cachedSortedUsers = sortedUsers && sortedUsers.map(user => {
    const all = messages[user.id];
    const length = all ? all.length : 0;
    const unread = messages && messages.statistics ? messages.statistics[user.id] : 0;

    return (
      <ChatUserListItem key={user.id}
                        user={user}
                        selected={selectedUser && selectedUser.id === user.id}
                        lastMessage={all ? all[length - 1] : null}
                        unreadMessage={unread}
                        data-user-id={user.id}
                        onClick={handleUserSelect} />
    );
  });

  return (
    <main className="Chat">
      <Row>
        <Col>
          {getMessagesPending && <div className="loading-indicator-container"><LoadingIndicator/></div>}
          {getMessagesSuccess &&
            <div className="chat">
              <div className={previewsClassNames}>
                {cachedSortedUsers}
              </div>

              <div className={chatContainerClassNames}>
                {selectedUser &&
                <Fragment>
                  <div className="chat-header">
                    <i className="fas fa-2x fa-chevron-left mr-3 d-block d-md-none" onClick={showPreviewHandler}/>

                    <div className={onlineClassName}>
                      <div className="profile-image" style={profileStyle}/>
                    </div>

                    <div className="chat-detail">
                      <div
                        className="username">{selectedUser && `${selectedUser.firstname} ${selectedUser.lastname}`}</div>
                      {selectedUser.city &&
                      <div><i className="fas fa-map-marker-alt mr-1"/>{selectedUser.city.name_tr}</div>}
                      {selectedUser.profession &&
                      <div><i className="fas fa-briefcase brown mr-1"/>{selectedUser.profession.name}</div>}
                    </div>

                    <div className="chat-buttons">
                      <Link to={RoutePaths.PROFILE.replace(":id", selectedUser.id)}><Button
                        variant="outline-primary btn-sm d-none d-lg-block">Profile Git</Button></Link>
                      <Button variant="outline-secondary btn-sm ml-4 d-none d-lg-block" onClick={handleDeleteMessages}>Konuşmayı
                        Sil</Button>
                      <div className="d-lg-none">
                        <Dropdown drop="left">
                          <Dropdown.Toggle variant="menu">
                            <i className="fas fa-ellipsis-v"/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item><Link to={RoutePaths.PROFILE.replace(":id", selectedUser.id)}>Profile
                              Git</Link></Dropdown.Item>
                            <Dropdown.Item><Link to="#" onClick={handleDeleteMessages}>Konuşmayı
                              Sil</Link></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  {getUserMessagesPending && <div className="loading-indicator-container"><LoadingIndicator/></div>}
                  {getUserMessagesSuccess &&
                    <Fragment>
                      <div className="chat-messages" ref={messageContainerRef}>
                        {selectedUser && messages && messages[selectedUser.id] && messages[selectedUser.id].map(original => {
                          const {id, from_user_id, createdAt, message} = original;
                          return (
                            <ChatMessageListItem key={id}
                                                 date={createdAt}
                                                 image={from_user_id === user.id ? user.image : selectedUser.image}
                                                 message={message}
                                                 sentByMe={from_user_id === user.id}/>
                          );
                        })}
                      </div>

                      <div className="type-message">
                        <input className="form-control input-message"
                               type="text"
                               placeholder="Mesaj yaz..."
                               value={message}
                               onChange={handleMessageChange}
                               onKeyDown={(event) => handleInputKeyDown(event)}/>

                        <img src={sendIcon} alt="" onClick={handleSendMessageClick}/>
                      </div>
                    </Fragment>
                  }
                </Fragment>
                }
              </div>
            </div>
          }
        </Col>
      </Row>
    </main>
  );
}

const mapStateToProps = state => {
  const {rootReducer: {
    messages,
    getMessagesPending,
    getMessagesSuccess,
    getUserMessagesPending,
    getUserMessagesSuccess,
    user,
  }} = state;

  return {
    messages,
    getMessagesPending,
    getMessagesSuccess,
    getUserMessagesPending,
    getUserMessagesSuccess,
    user,
  };
};

export default connect(mapStateToProps)(Chat);

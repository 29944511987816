import React, {Component}  from "react";
import {Row, Col, Button, Form} from "react-bootstrap";
import validate from "validate.js";
import Select from "react-select";
import Resizer from "react-image-file-resizer";
import {Link} from "react-router-dom";

import actions from "../../../redux/actions";
import "./SignUp.scss";
import ProfileDummyImage from "../../../assets/images/profile-icon.svg";
import {connect} from "react-redux";
import RoutePaths from "../../../constants/RoutePaths";
import api from "../../../services/api";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Modal from "react-bootstrap/Modal";
import {customProfessionFilter} from "../../../utils/stringUtils";


class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: {
        value: "",
        error: undefined,
      },
      lastname: {
        value: "",
        error: undefined,
      },
      email: {
        value: "",
        error: undefined,
      },
      password: {
        value: "",
        error: undefined,
      },
      confirmPassword: {
        value: "",
        error: undefined,
      },
      profileImage: {
        file: "",
        previewUrl: null
      },
      profession: {
        value: null,
        error: undefined,
      },
      city: {
        value: null,
        error: undefined,
      },
      description: {
        value: "",
        error: undefined,
      },
      consentVisibleForSearchResults: {
        value: true,
        error: undefined,
      },
      consentTerms: {
        value: false,
        error: undefined,
      },
      showModal: false,
      modalTitle: "",
      modalContent: "",
    };

    //CONSTRAINTS!!!
    this.constraints = {
      firstname: {
        presence: {allowEmpty: false, message: "^Lütfen adınızı yazınız."},
      },
      lastname: {
        presence: {allowEmpty: false, message: "^Lütfen soyadınızı yazınız."},
      },
      email: {
        email: {allowEmpty: false, message: "^Lütfen email adresinizi yazınız."},
      },
      password: {
        length: {
          message: "^Şifrenizin uzunluğu en az 6 karakter olmalı.",
          minimum: 6,
        }
      },
      confirmPassword: {
        presence: {allowEmpty: false, message: "^Lütfen şifre tekrarınızı yazınız."},
        equality: {
          attribute: "password",
          message: "^Şifre tekrarınız eşleşmiyor."
        }
      },
      consentTerms: {
        presence: {
          message: "^Sözleşmeyi onaylamanız gerekmekte."
        },
        inclusion: {
          within: [true],
          message: "^Sözleşmeyi onaylamanız gerekmekte."
        }
      },
      profession: function(value, attributes) {
        if (!attributes.consentVisibleForSearchResults) return null;
        return {
          presence: {allowEmpty: false, message: "^Arama sonuçlarında görünmek için meslek girmek zorundasınız."}
        };
      },
      city: function(value, attributes) {
        if (!attributes.consentVisibleForSearchResults) return null;
        return {
          presence: {allowEmpty: false, message: "^Arama sonuçlarında görünmek için şehir girmek zorundasınız."}
        };
      }
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleProfessionChange = this.handleProfessionChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {registerSuccess, registerError} = this.props;

    if(registerSuccess !== prevProps.registerSuccess) {
      this.setState({
        showModal: true,
        modalTitle: "Üyelik talebin alındı.",
        modalContent: "Şimdi sana e-mail yolladık. Gelen e-maili onayladığın taktirde üyeliğin aktif olacaktır. Bazen e-mailler spam veya junk a düşebiliyor. Oralara da bakmanı öneririz."
      });
    } else if(registerError !== prevProps.registerError) {
      this.setState({
        showModal: true,
        modalTitle: "Hata",
        modalContent: "Bu email adresi kullanılıyor. Lütfen başka email adresi deneyin."
      });
    }
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const {firstname, lastname, email, password, confirmPassword, profession, city, description, consentVisibleForSearchResults, consentTerms, profileImage, professionGroupId} = this.state;
    const {dispatch} = this.props;

    let validateResult = validate({
      firstname: firstname.value,
      lastname: lastname.value,
      email: email.value,
      password: password.value,
      confirmPassword: confirmPassword.value,
      profession: profession.value,
      city: city.value,
      consentVisibleForSearchResults: consentVisibleForSearchResults.value,
      consentTerms: consentTerms.value,
    }, this.constraints);

    if(validateResult === undefined) {

      dispatch(actions.postRegister(
        firstname.value,
        lastname.value,
        email.value,
        password.value,
        city.value,
        profession.value,
        description.value,
        consentVisibleForSearchResults.value,
        profileImage.file,
        professionGroupId
      ));

    } else {
      const {firstname: firstnameMessage, lastname: lastnameMessage, email: emailMessage, password: passwordMessage, confirmPassword: confirmPasswordMessage, profession: professionMessage, city: cityMessage, consentVisibleForSearchResults: consentVisibleForSearchResultsMessage, consentTerms: consentTermsMessage} = validateResult;
      this.setState(prevState => ({
        firstname: {
          value: prevState.firstname.value,
          error: firstnameMessage
        },
        lastname: {
          value: prevState.lastname.value,
          error: lastnameMessage
        },
        email: {
          value: prevState.email.value,
          error: emailMessage
        },
        password: {
          value: prevState.password.value,
          error: passwordMessage
        },
        confirmPassword: {
          value: prevState.confirmPassword.value,
          error: confirmPasswordMessage
        },
        profession: {
          value: prevState.profession.value,
          error: professionMessage
        },
        city: {
          value: prevState.city.value,
          error: cityMessage
        },
        consentVisibleForSearchResults: {
          value: prevState.consentVisibleForSearchResults.value,
          error: consentVisibleForSearchResultsMessage
        },
        consentTerms: {
          value: prevState.consentTerms.value,
          error: consentTermsMessage
        }
      }));
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.name === "firstname" || target.name === "lastname") {
      const extract = (str, pattern) => (str.match(pattern) || []).pop() || "";
      value = extract(value, "[a-zA-Z0-9ğüşöçİĞÜŞÖÇ ]+");
    }

    this.setState({
      [name]: {
        value: value,
        error: undefined
      }
    });
  }

  handleImageChange(event) {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {

      if(file) {
        Resizer.imageFileResizer(
          file,
          600,
          600,
          "JPEG",
          100,
          0,
          uri => {
            this.setState({
              profileImage: {
                file: uri.split(",")[1],
                previewUrl: uri
              }
            });
          },
          "base64"
        );
      }
    };

    reader.readAsDataURL(file);
  }

  cityLoadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 2) {
      if (inputValue.length > 2) {
        api.getCitySuggestions(inputValue).then((citySuggestions) => {
          const temp = citySuggestions.map((value) => {
            return {
              value: value.id,
              label: value.name_tr + " / " + value.country.name
            };
          });
          callback(temp);
        });
      }
    }
  };

  reactSelectTheme(theme) {
    return(
      {
        ...theme,
        colors: {
          ...theme.colors,
          primary50: "#f8f9fa",
          primary25: "#f8f9fa",
          primary: "#ff2a48",
        },
      }
    );
  }

  handleCityChange(option, action){
    if(action.action === "select-option") {
      const {value} = option;

      this.setState({
        city: {
          value: value,
          error: undefined,
        }
      });
    }
  }

  handleProfessionChange(option, action){
    if(action.action === "select-option") {
      const {value, groupId} = option;

      this.setState({
        profession: {
          value: value,
          error: undefined,
        },
        professionGroupId: groupId
      });
    }
  }

  handleModalClose() {
    const {registerSuccess, history} = this.props;

    if(registerSuccess) {
      history.push(RoutePaths.HOME);
    }

    this.setState({
      showModal: false
    });
  }

  render() {
    const {firstname, lastname, email, password, confirmPassword, profileImage, profession, city, description, consentVisibleForSearchResults, consentTerms, showModal, modalTitle, modalContent} = this.state;
    const {registerPending, professions} = this.props;

    let previewImageSource;
    if (profileImage.previewUrl) {
      previewImageSource = profileImage.previewUrl;
    } else {
      previewImageSource = ProfileDummyImage;
    }

    return (
      <main className="sign-up">
        <Modal show={showModal} onHide={this.handleModalClose} centered>
          <Modal.Header>
            <Modal.Title className="text-center w-100"><i className="fas fa-3x fa-envelope red" /></Modal.Title>
          </Modal.Header>
          <div className="text-center"><b>{modalTitle}</b></div>
          <Modal.Body className="text-center">{modalContent}</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button onClick={() => this.handleModalClose()} variant="outline-primary">
              Tamam
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-center">
          <Col sm={12} md={8}>
            <div className="login-top-nav">
              <Link className="active" to={RoutePaths.SIGN_UP}>KAYIT OL</Link>
              <Link to={RoutePaths.LOGIN}>GİRİŞ YAP</Link>
            </div>
            <div className="login-card">
              <Form onSubmit={this.handleFormSubmit}>
                <Form.Group>
                  <div onClick={() => this.inputElement.click()} className="profile-outer">
                    <div className="image">
                      <img src={previewImageSource} alt=""/>
                      <div className="plus-icon">
                        <i className="fas fa-plus"/>
                      </div>
                    </div>
                  </div>
                  <Form.Control ref={input => this.inputElement = input} className="d-none" type="file" name="profileImage" accept="image/*" onChange={this.handleImageChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Ad: </Form.Label>
                  <Form.Control type="text" autoComplete="new-name" name="firstname" value={firstname.value} onChange={this.handleInputChange} isInvalid={firstname.error}/>
                  <Form.Control.Feedback type="invalid">{firstname.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Soyad: </Form.Label>
                  <Form.Control type="text" autoComplete="new-surname" name="lastname" value={lastname.value} onChange={this.handleInputChange} isInvalid={lastname.error}/>
                  <Form.Control.Feedback type="invalid">{lastname.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>E-mail: </Form.Label>
                  <Form.Control type="text" autoComplete="new-email" name="email" value={email.value} onChange={this.handleInputChange} isInvalid={email.error}/>
                  <Form.Control.Feedback type="invalid">{email.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Şifre: </Form.Label>
                  <Form.Control type="password" autoComplete="new-password" name="password" value={password.value} onChange={this.handleInputChange} isInvalid={password.error}/>
                  <Form.Control.Feedback type="invalid">{password.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Şifre Tekrar: </Form.Label>
                  <Form.Control type="password" autoComplete="new-password" name="confirmPassword" value={confirmPassword.value} onChange={this.handleInputChange} isInvalid={confirmPassword.error}/>
                  <Form.Control.Feedback type="invalid">{confirmPassword.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Şehir: </Form.Label>
                  <AsyncSelect
                    theme={(theme) => this.reactSelectTheme(theme)}
                    loadingMessage={()=>("Aranıyor...")}
                    noOptionsMessage={()=>("Lütfen yaşadığınız şehri yazınız.")}
                    cacheOptions
                    loadOptions={this.cityLoadOptions}
                    onChange={this.handleCityChange}
                    placeholder="Şehir seç"
                  />
                  <Form.Control.Feedback className={city.error ? "d-block" : "d-none"} type="invalid">{city.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Meslek Seçimi: </Form.Label>
                  <Select
                    theme={(theme) => this.reactSelectTheme(theme)}
                    loadingMessage={()=>("Aranıyor...")}
                    noOptionsMessage={()=>("Lütfen mesleğinizi yazınız.")}
                    onChange={this.handleProfessionChange}
                    options={professions}
                    filterOption={customProfessionFilter}
                    placeholder="Meslek seç"/>
                  <Form.Control.Feedback className={profession.error ? "d-block" : "d-none"} type="invalid">{profession.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Açıklama: </Form.Label>
                  <Form.Control name="description" value={description.value} onChange={this.handleInputChange} as="textarea" rows="3" />
                </Form.Group>
                <Form.Group>
                  <Form.Check type="checkbox" label="Arama sonuçlarında görünmek istiyorum." name="consentVisibleForSearchResults" onChange={this.handleInputChange} checked={consentVisibleForSearchResults.value} isInvalid={consentVisibleForSearchResults.error}/>
                </Form.Group>
                <Form.Group>
                  <input type="checkbox" className="mr-1" name="consentTerms" onChange={this.handleInputChange} checked={consentTerms.value}/>
                  <a href={RoutePaths.LEGAL} target="_blank">Bizden Kim Var Kullanıcı Anlaşmasını, Gizlilik Politikasını ve Çerez Politikasını kabul ediyorum.</a>
                  <Form.Control.Feedback className={profession.error ? "d-block" : "d-none"} type="invalid">{consentTerms.error}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  {registerPending ? (
                    <Button variant="primary btn-lg" type="submit" disabled>BİZE KATIL</Button>
                    ) : (
                      <Button variant="primary btn-lg" type="submit">BİZE KATIL</Button>
                    )
                  }
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const {rootReducer: {registerPending, registerSuccess, registerError, citySuggestions, professions}} = state;

  return {
    citySuggestions,
    registerPending,
    registerSuccess,
    registerError,
    professions
  };
};

export default connect(mapStateToProps)(SignUp);

import Container from "./Container";
import React, {Component} from "react";
import {Link, Router} from "react-router-dom";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import {createStore, applyMiddleware, compose, combineReducers} from "redux";
import { createBrowserHistory } from "history";

import actions from "./redux/actions";
import rootReducer, {initialState} from "./redux/reducers";
import mySaga from "./sagas/sagas";
import Api from "./services/api";

import "./App.scss";
import CookieConsent from "react-cookie-consent";
import RoutePaths from "./constants/RoutePaths";

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(combineReducers({rootReducer: rootReducer}), {rootReducer: initialState}, composeEnhancers(
  applyMiddleware(sagaMiddleware)
));

sagaMiddleware.run(mySaga);

class App extends Component {

  componentDidMount() {
    store.dispatch(actions.getProfessions());
    store.dispatch(actions.reAuthenticate());

    Api.setStore(store);
  }

  render() {
    return(
      <Provider store={store}>
        <Router history={history}>
          <Container history={history} />
          <CookieConsent
            location="bottom"
            buttonText="Kapat"
            cookieName="bizdenkimvar_cookie_policy"
            style={{ backgroundColor: "rgba(171, 171, 171, 0.9)" }}
            buttonStyle={{fontSize: "13px", backgroundColor: "#FFFFFF" }}
            expires={150}
          >
            Sitemizden en iyi şekilde faydalanabilmen için çerezler kullanılmaktadır. Bu siteye giriş yaparak çerez kullanımını kabul etmiş sayılıyorsunuz. <Link to={RoutePaths.COOKIE_POLICY}>Detaylı bilgi için tıklayın.</Link>
          </CookieConsent>
        </Router>
      </Provider>
    );
  }
}

export default App;

import {ActionTypes} from "../actions";
import messagingUtils from "../../utils/messagingUtils";

const quotes = [
  {
    "profession": "Mimar",
    "location": "London, UK",
    "name": "Hale Kurtuluş",
    "quote": "Tokyo`daki toplantımıza gitmemize günler kala tercümanımızın gelemeyeceğinin haberini aldık. bizdenkimvar.com sayesinde Tokyo`da yasayan bizden birini bulup 1 saat içinde her şeyi ayarladık. İyi ki varsınız!” ",
  },
  {
    "profession": "Mimar",
    "location": "London, UK",
    "name": "A. Aktan",
    "quote": "Londra`ya yerleşme planı yaparken, bizdenkimvar sayesinde orada hizmet veren Avukat arkadaşlarla iletişime geçtik. Tüm bilgileri doğru kişiden edinmenin verdiği rahatlıkla islerimizi halletme yolundayız",
  },
  {
    "profession": "Mimar",
    "location": "London, UK",
    "name": "Kerem Gönlübüyük",
    "quote": "4 senedir ailecek Moskova`da yasamaktayız. 7 yaşındaki oğlumun Türkçesine destek olması acısından ozel ders veren öğretmen aradım ve anında buldum. Çok teşekkürler :)",
  },
  {
    "profession": "Mimar",
    "location": "London, UK",
    "name": "Cansu Ataman",
    "quote": "Erasmus programı ile Madrid`e gitmeden bizdenkimvar.com üzerinden bölümümüzden arkadaşlarla yazıştık, çok yardımları dokundu. Teşekkürler bizdenkimvar.com",
  }
];

export const initialState = {
  quotes: null,
  quoteIndex: 0,

  blockUserPending: false,
  blockUserSuccess: false,
  blockUserError: null,

  unBlockUserPending: false,
  unBlockUserSuccess: false,
  unBlockUserError: null,

  reAuthenticatePending: true,
  reAuthenticateSuccess: false,
  reAuthenticateError: null,

  loginPending: false,
  loginSuccess: false,
  loginError: null,

  updateUserPending: false,
  updateUserSuccess: false,
  updateUserError: null,

  registerPending: false,
  registerSuccess: false,
  registerError: null,

  getUsersPending: false,
  getUsersSuccess: false,
  getUsersError: null,

  getRelatedUsersPending: false,
  getRelatedUsersSuccess: false,
  getRelatedUsersError: null,

  getUserPending: false,
  getUserSuccess: false,
  getUserError: null,

  changePasswordPending: false,
  changePasswordSuccess: false,
  changePasswordError: null,

  citySuggestions: [],

  users: null,
  usersTotal: null,
  usersSkip: null,
  usersLimit: null,
  relatedUsers: null,

  user: null,

  selectedUser: null,

  messages: [],
  getMessagesPending: false,
  getMessagesSuccess: false,
  getMessagesError: null,

  getUserMessagesPending: false,
  getUserMessagesSuccess: false,
  getUserMessagesError: null,

  getTextsPending: false,
  getTextsSuccess: true,
};

export default function(state = initialState, action) {
  let newState;

  switch (action.type) {
    case ActionTypes.BLOCK_USER: {
      newState = {
        ...state,
        blockUserPending: true,
        blockUserSuccess: false,
        blockUserError: null,
      };

      break;
    }

    case ActionTypes.BLOCK_USER_FULFILLED: {
      newState = {
        ...state,
        blockUserPending: false,
        blockUserSuccess: true,
        blockUserError: null,
        selectedUser: action.payload.toUser
      };

      break;
    }

    case ActionTypes.BLOCK_USER_FAILED: {
      newState = {
        ...state,
        blockUserPending: false,
        blockUserSuccess: false,
        blockUserError: action.payload.error,
      };

      break;
    }

    case ActionTypes.UNBLOCK_USER: {
      newState = {
        ...state,
        unBlockUserPending: true,
        unBlockUserSuccess: false,
        unBlockUserError: null
      };

      break;
    }

    case ActionTypes.UNBLOCK_USER_FULFILLED: {
      newState = {
        ...state,
        unBlockUserPending: false,
        unBlockUserSuccess: true,
        unBlockUserError: null,
        selectedUser: action.payload.result[0].toUser
      };

      break;
    }

    case ActionTypes.UNBLOCK_USER_FAILED: {
      newState = {
        ...state,
        unBlockUserPending: false,
        unBlockUserSuccess: false,
        unBlockUserError: action.payload.error,
      };

      break;
    }

    case ActionTypes.RESET_RELATED_USERS: {
      newState = {
        ...state,
        relatedUsers: null,
      };

      break;
    }

    case ActionTypes.RESET_HOME_SCREEN: {
      newState = {
        ...state,
        relatedUsers: null,
        users: null,
        usersTotal: null,
        usersSkip: null,
        usersLimit: null
      };

      break;
    }

    case ActionTypes.RESET_CHAT: {
      newState = {
        ...state,
        messages: [],
      };

      break;
    }

    case ActionTypes.RESET_SELECTED_USER: {
      newState = {
        ...state,
        selectedUser: null,
      };

      break;
    }

    case ActionTypes.ROUTE_PROFILE: {
      newState = {
        ...state,
        selectedUser: action.payload.selectedUser
      };

      break;
    }

    case ActionTypes.GET_CITY_SUGGESTIONS_FULFILLED: {
      newState = {
        ...state,
        citySuggestions: action.payload.cities.map((value) => {
          return {
            id: value.id,
            label: value.name
          };
        }),
      };

      break;
    }

    case ActionTypes.NEXT_QUOTE: {
      newState = {
        ...state,
        quoteIndex: (state.quoteIndex + 1) % state.quotes.length,
      };

      break;
    }

    case ActionTypes.PREVIOUS_QUOTE: {
      newState = {
        ...state,
        quoteIndex: state.quoteIndex > 0 ? (state.quoteIndex - 1) % state.quotes.length : state.quotes.length - 1,
      };

      break;
    }

    case ActionTypes.POST_LOGOUT: {
      newState = {
        ...state,
        user: null
      };

      break;
    }

    case ActionTypes.RE_AUTHENTICATE: {
      newState = {
        ...state,
        reAuthenticatePending: true,
        reAuthenticateError: null,
      };

      break;
    }

    case ActionTypes.RE_AUTHENTICATE_FULFILLED: {
      newState = {
        ...state,
        reAuthenticatePending: false,
        reAuthenticateError: null,
        user: action.payload.authResult.user,
      };

      break;
    }

    case ActionTypes.RE_AUTHENTICATE_FAILED: {
      newState = {
        ...state,
        reAuthenticatePending: false
      };

      break;
    }

    case ActionTypes.POST_LOGIN: {
      newState = {
        ...state,
        loginPending: true,
        loginSuccess: false,
        loginError: null,
      };

      break;
    }

    case ActionTypes.POST_LOGIN_FULFILLED: {
      newState = {
        ...state,
        loginPending: false,
        loginSuccess: true,
        loginError: null,
        user: action.payload.authResult.user,
      };

      break;
    }

    case ActionTypes.POST_LOGIN_FAILED: {
      newState = {
        ...state,
        loginPending: false,
        loginSuccess: false,
        loginError: action.payload.error,
      };

      break;
    }

    case ActionTypes.POST_REGISTER: {
      newState = {
        ...state,
        registerPending: true,
        registerError: null,
      };

      break;
    }

    case ActionTypes.POST_REGISTER_FULFILLED: {
      newState = {
        ...state,
        registerPending: false,
        registerError: null,
        registerSuccess: true,
        user: action.payload.authResult.user,
      };

      break;
    }

    case ActionTypes.POST_REGISTER_FAILED: {
      newState = {
        ...state,
        registerPending: false,
        registerError: action.payload.error,
      };

      break;
    }

    case ActionTypes.UPDATE_USER: {
      newState = {
        ...state,
        updateUserPending: true,
        updateUserSuccess: false,
        updateUserError: null,
      };

      break;
    }

    case ActionTypes.UPDATE_USER_FULFILLED: {
      newState = {
        ...state,
        updateUserPending: false,
        updateUserSuccess: true,
        updateUserError: null,
        user: action.payload.user,
      };

      break;
    }

    case ActionTypes.UPDATE_USER_FAILED: {
      newState = {
        ...state,
        updateUserPending: false,
        updateUserSuccess: false,
        updateUserError: action.payload.error,
      };

      break;
    }

    case ActionTypes.DELETE_USER: {
      newState = {
        ...state,
        deleteUserPending: true,
        deleteUserError: null,
      };

      break;
    }

    case ActionTypes.DELETE_USER_FULFILLED: {
      newState = {
        ...state,
        deleteUserPending: false,
        deleteUserError: null,
        deleteUserSuccess: true,
        selectedUser: action.payload.user,
      };

      break;
    }

    case ActionTypes.DELETE_USER_FAILED: {
      newState = {
        ...state,
        deleteUserPending: false,
        deleteUserError: action.payload.error,
      };

      break;
    }

    case ActionTypes.GET_USER: {
      newState = {
        ...state,
        getUserPending: true,
        getUserError: null,
      };

      break;
    }

    case ActionTypes.GET_USER_FULFILLED: {
      newState = {
        ...state,
        getUserPending: false,
        getUserError: null,
        getUserSuccess: true,
        selectedUser: action.payload.user,
      };

      break;
    }

    case ActionTypes.GET_USER_FAILED: {
      newState = {
        ...state,
        getUserPending: false,
        getUserError: action.payload.error,
      };

      break;
    }

    case ActionTypes.GET_RELATED_USERS: {
      newState = {
        ...state,
        getRelatedUsersPending: true,
        getRelatedUsersSuccess: false,
        getRelatedUsersError: null,
      };

      break;
    }

    case ActionTypes.GET_RELATED_USERS_FULFILLED: {
      newState = {
        ...state,
        getRelatedUsersPending: false,
        getRelatedUsersError: null,
        getRelatedUsersSuccess: true,
        relatedUsers: action.payload.result.data,
      };

      break;
    }

    case ActionTypes.GET_RELATED_USERS_FAILED: {
      newState = {
        ...state,
        getRelatedUsersPending: false,
        getRelatedUsersSuccess: false,
        getRelatedUsersError: action.payload.error,
      };

      break;
    }

    case ActionTypes.GET_USERS: {
      newState = {
        ...state,
        getUsersPending: true,
        getUsersError: null,
      };

      break;
    }

    case ActionTypes.GET_USERS_FULFILLED: {
      newState = {
        ...state,
        getUsersPending: false,
        getUsersError: null,
        getUsersSuccess: true,
        users: action.payload.result.data,
        usersTotal: action.payload.result.total,
        usersLimit: action.payload.result.limit,
        usersSkip: action.payload.result.skip
      };

      break;
    }

    case ActionTypes.GET_USERS_FAILED: {
      newState = {
        ...state,
        getUsersPending: false,
        getUsersError: action.payload.error,
      };

      break;
    }

    case ActionTypes.CHANGE_PASSWORD: {
      newState = {
        ...state,
        changePasswordPending: true,
        changePasswordError: null,
      };

      break;
    }

    case ActionTypes.CHANGE_PASSWORD_FULFILLED: {
      newState = {
        ...state,
        changePasswordPending: false,
        changePasswordError: null,
        changePasswordSuccess: true
      };

      break;
    }

    case ActionTypes.CHANGE_PASSWORD_FAILED: {
      newState = {
        ...state,
        changePasswordPending: false,
        changePasswordError: action.payload.error,
      };

      break;
    }


    case ActionTypes.GET_TEXTS: {
      newState = {
        ...state,
        getTextsPending: true,
        getTextsError: null,
      };

      break;
    }

    case ActionTypes.GET_TEXTS_FULFILLED: {
      const quotesJsonString = action.payload.texts.filter((item) => {
        return item.name === "quotes";
      })[0].jsonValue;

      const title = action.payload.texts.filter((item) => {
        return item.name === "title";
      })[0].value;

      const json = JSON.parse(quotesJsonString);

      newState = {
        ...state,
        getTextsPending: false,
        getTextsError: null,
        getTextsSuccess: true,
        quotes: json.quotes,
        title,
      };

      break;
    }

    case ActionTypes.GET_TEXTS_FAILED: {
      newState = {
        ...state,
        getTextsPending: false,
      };

      break;
    }


    case ActionTypes.GET_PROFESSIONS: {
      newState = {
        ...state,
        getProfessionsPending: true,
        getProfessionsError: null,
      };

      break;
    }

    case ActionTypes.GET_PROFESSIONS_FULFILLED: {
      newState = {
        ...state,
        getProfessionsPending: false,
        getProfessionsError: null,
        getProfessionsSuccess: true,
        professions: action.payload.professions.map((value) => {
          return {
            value: value.id,
            label: value.name,
            groupId: value.group_id
          };
        }),
      };

      break;
    }

    case ActionTypes.GET_PROFESSIONS_FAILED: {
      newState = {
        ...state,
        getProfessionsPending: false,
        getProfessionsError: action.payload.error,
      };

      break;
    }

    case ActionTypes.ADD_MESSAGE_TO_STORE: {
      newState = {
        ...state,
        messages: {
          ...messagingUtils.processNewMessage(state.messages, action.payload.message, state.user ? state.user.id : 0),
          randomize: Math.random(),
        }
      };

      break;
    }

    case ActionTypes.ADD_MESSAGE_USER_TO_STORE: {
      newState = {
        ...state,
      };

      newState.messages.users = newState.messages.users || [];
      newState.messages.users = [
        ...newState.messages.users.filter(u => u.id !== action.payload.toUser.id),
        action.payload.toUser,
      ];
      newState.messages[action.payload.toUser.id] = [];
      newState.messages.randomize = Math.random();

      break;
    }

    case ActionTypes.PATCH_MESSAGE_IN_STORE: {
      newState = {
        ...state,
        messages: {
          ...messagingUtils.patchMessage(state.messages, action.payload.message, state.user ? state.user.id : 0),
          randomize: Math.random(),
        }
      };

      break;
    }

    case ActionTypes.GET_MESSAGES: {
      newState = {
        ...state,
        getMessagesPending: true,
        getMessagesSuccess: false,
        getMessagesError: null,
      };

      break;
    }

    case ActionTypes.GET_MESSAGES_FULFILLED: {
      newState = {
        ...state,
        getMessagesPending: false,
        getMessagesSuccess: true,
        getMessagesError: null,
        messages: {
          ...messagingUtils.processMessages(state.messages, action.payload.messages, action.payload.clearEmpty),
          randomize: Math.random(),
        },
      };

      break;
    }

    case ActionTypes.GET_MESSAGES_FAILED: {
      newState = {
        ...state,
        getMessagesPending: false,
        getMessagesSuccess: false,
        getMessagesError: action.payload.error,
      };

      break;
    }

    case ActionTypes.GET_MESSAGES_OF_USER: {
      newState = {
        ...state,
        getUserMessagesPending: true,
        getUserMessagesSuccess: false,
        getUserMessagesError: null,
      };

      break;
    }

    case ActionTypes.GET_MESSAGES_OF_USER_FULFILLED: {
      newState = {
        ...state,
        getUserMessagesPending: false,
        getUserMessagesSuccess: true,
        getUserMessagesError: null,
        messages: {
          ...messagingUtils.processUserMessages(state.messages, action.payload.messages, action.payload.uid),
          randomize: Math.random(),
        },
      };

      break;
    }

    case ActionTypes.SAVE_SEARCH_STATE: {
      newState = {
        ...state,
        cityId: action.payload.cityId,
        professionId: action.payload.professionId,
        professionGroupId: action.payload.professionGroupId,
      };

      break;
    }

    case ActionTypes.PAGE_REFRESH: {
      newState = {
        ...state,
        pageRefresh: action.payload.pageRefresh
      };

      break;
    }

    case ActionTypes.GET_MESSAGES_OF_USER_FAILED: {
      newState = {
        ...state,
        getUserMessagesPending: false,
        getUserMessagesSuccess: false,
        getUserMessagesError: action.payload.error,
      };

      break;
    }



    default: {
      newState = {
        ...state,
      };
    }
  }

  return newState;
}

import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { RoutePaths } from "./constants";
import {Report, ConfirmDelete, Home, Search, Profile, ChangePassword, WhoWeAre, SignUp, Login, Information, Chat, ForgetPassword, Verify, Welcome, EmptySearch, Legal} from "./components/pages";
import PrivateRoute from "./utils/router/PrivateRoute";
import CookiePolicy from "./components/pages/cookie-policy/CookiePolicy";

function AppRoutes() {
  return (
    <Switch>
      <Route path={RoutePaths.HOME} component={Home}/>
      <Route path={RoutePaths.LIST} component={Search} />
      <Route path={RoutePaths.PROFILE} component={Profile} />
      <PrivateRoute path={RoutePaths.ME} component={Profile} />
      <PrivateRoute path={RoutePaths.CHANGE_PASSWORD} component={ChangePassword} />
      <Route path={RoutePaths.WHO_WE_ARE} component={WhoWeAre} />
      <Route path={RoutePaths.CONFIRM_DELETE} component={ConfirmDelete} />
      <Route path={RoutePaths.SIGN_UP} component={SignUp} />
      <Route path={RoutePaths.LOGIN} component={Login} />
      <Route path={RoutePaths.INFORMATION} component={Information} />
      <Route path={RoutePaths.WELCOME} component={Welcome} />
      <Route path={RoutePaths.EMPTY_SEARCH} component={EmptySearch} />
      <Route path={RoutePaths.LEGAL} component={Legal} />
      <PrivateRoute path={RoutePaths.CHAT_WITH_ID} component={Chat} />
      <PrivateRoute path={RoutePaths.CHAT} component={Chat} />
      <Route path={RoutePaths.FORGET_PASSWORD} component={ForgetPassword} />
      <Route path={RoutePaths.VERIFY} component={Verify} />
      <PrivateRoute path={RoutePaths.REPORT} component={Report} />
      <Route path={RoutePaths.COOKIE_POLICY} component={CookiePolicy} />

      {/* Redirection on 404 or some mismatch url */}
      <Redirect to={RoutePaths.HOME} />
    </Switch>
  );
}

export default AppRoutes;

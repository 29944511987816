import {disclaimers} from "../constants";
import _ from "lodash";

const processMessages = (oldMessages, messages, clearEmpty = false) => {
  if (oldMessages && oldMessages.users) {
    const calculated = clearEmpty ? [...messages.users] : [...messages.users, ...oldMessages.users];
    messages.users = _.uniqBy(calculated, _.property("id"));
  }

  messages.users.forEach(user => {
    messages[user.id] = messages[user.id] || [];
  });

  if (clearEmpty) {
    messages.users = messages.users.filter(user => messages[user.id] && messages[user.id].length > 0);
  }

  return messages;
};

const processUserMessages = (old, messages, uid) => {
  let processed = {
    ...old
  };

  if (processed[uid]) {
    const oldCount = processed.statistics[uid];

    if (oldCount !== undefined) {
      processed[uid] = [];
      processed.statistics[uid] = 0;
      processed.statistics.totalUnread -= oldCount;
    }
  } else {
    return processed;
  }

  messages.forEach(message => {
    if (!message.otherUser) {
      return;
    }

    let otherUser = message.otherUser, isMessageRead = false;
    if (message.from_user_id === message.other_user_id) {
      isMessageRead = message.to_is_read === 1;
    } else if (message.to_user_id === message.other_user_id) {
      isMessageRead = message.from_is_read === 1;
    }

    if (otherUser) {
      if (processed.users.findIndex(u => u.id === otherUser.id) > -1) {
        processed[otherUser.id].push(message);
      } else {
        processed.users.push(otherUser);
        processed[otherUser.id] = [message];
      }

      if (!isMessageRead) {
        processed.statistics.totalUnread += 1;
        processed.statistics[otherUser.id] = processed.statistics[otherUser.id] || 0;
        processed.statistics[otherUser.id] += 1;
      }
    }

    delete message.otherUser;
  });

  return processed;
};

const processNewMessage = (messages, newMessage, uid) => {
  const processed = {
    ...messages,
  };

  let otherUser, isMessageRead = false;
  if (newMessage.from_user_id === uid) {
    otherUser = newMessage.toUser;
    isMessageRead = newMessage.from_is_read === 1;
  } else if (newMessage.to_user_id === uid) {
    otherUser = newMessage.fromUser;
    isMessageRead = newMessage.to_is_read === 1;
  }

  if (otherUser) {
    if (processed.users.findIndex(user => user.id === otherUser.id) > -1) {
      processed[otherUser.id].push(newMessage);
    } else {
      processed.users.push(otherUser);
      processed[otherUser.id] = [newMessage];
    }

    if (!isMessageRead) {
      processed.statistics.totalUnread += 1;
      processed.statistics[otherUser.id] = processed.statistics[otherUser.id] || 0;
      processed.statistics[otherUser.id] += 1;
    }
  }

  return processed;
};

const patchMessage = (messages, patchedMessage, uid) => {
  const processed = {
    ...messages,
  };

  let otherUser, isMessageRead = false;
  if (patchedMessage.from_user_id === uid) {
    otherUser = patchedMessage.toUser;
    isMessageRead = patchedMessage.from_is_read === 1;
  } else if (patchedMessage.to_user_id === uid) {
    otherUser = patchedMessage.fromUser;
    isMessageRead = patchedMessage.to_is_read === 1;
  }

  if (otherUser) {
    const index = processed[otherUser.id].findIndex(m => m.id === patchedMessage.id);
    if (index > -1) {
      const isOldMessageRead = processed[otherUser.id][index].from_user_id === otherUser.id &&
        processed[otherUser.id][index].to_is_read === 1;

      processed[otherUser.id][index] = patchedMessage;

      const addition = (isMessageRead ? 0 : 1) - (isOldMessageRead ? 0 : 1);
      processed.statistics.totalUnread += addition;
      processed.statistics.totalUnread = Math.max(0, processed.statistics.totalUnread);
      processed.statistics[otherUser.id] = processed.statistics[otherUser.id] || 0;
      processed.statistics[otherUser.id] += addition;
      processed.statistics[otherUser.id] = Math.max(0, processed.statistics[otherUser.id]);
    }
  }

  processed.users = processed.users.filter(user => processed[user.id] && processed[user.id].length > 0);

  return processed;
};


const formatMessage = (message) => {
  if (message === "%%disclaimer%%") {
    return disclaimers.find(d => d.version === "").message;
  } else {
    return message;
  }
};

export default {
  processMessages,
  processUserMessages,
  processNewMessage,
  patchMessage,
  formatMessage,
}

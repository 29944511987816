import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import {RoutePaths} from "../../../constants";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";

import "./Header.scss";
import Logo from "../../../assets/images/logo-red.svg";
import {connect} from "react-redux";
import actions from "../../../redux/actions";
import Badge from "react-bootstrap/Badge";
import AppRoutes from "../../../AppRoutes";

class Header extends Component {

  constructor(props) {
    super(props);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);
  }

  handleLogoutClick() {
    const {dispatch} = this.props;
    dispatch(actions.postLogout());
    dispatch(actions.resetChat());
  }

  handleLogoClick () {
    const {dispatch, history} = this.props;
    dispatch(actions.resetHomeScreen());
    history.push(RoutePaths.HOME);
  }

  render() {
    const {user, messages} = this.props;
    const path = this.props.location.pathname;

    let showLogo = true;

    switch (path) {
      case RoutePaths.HOME:
        showLogo = false;
        break;
    }


    let mobileBadge;
    let badge;

    if (messages && messages.statistics && messages.statistics.totalUnread) {
      let unreadCount = messages.statistics.totalUnread;
      badge = <span className="badge badge-pill badge-notify-button">{unreadCount}</span>;
      mobileBadge = <span className="badge badge-pill badge-notify">{unreadCount}</span>;
    }

    return (
      <Row>
        <Col className="header">
          {showLogo &&
          <a href="#" onClick={this.handleLogoClick}><img className="d-md-inline" alt="Logo" src={Logo}/></a>
          }

          <div className="right">
            <div className="d-none d-md-block">
              <Link to={RoutePaths.WHO_WE_ARE}><Button variant="outline-primary btn-lg">KİMLER BİZDEN</Button></Link>

              {user && <Link to={RoutePaths.ME}><Button variant="outline-primary btn-lg ml-4 position-relative">{user.firstname.toLocaleUpperCase("tr")} {user.lastname.toLocaleUpperCase("tr")} {badge} </Button></Link>}

              {user ?
                <Button onClick={this.handleLogoutClick} variant="primary btn-lg ml-4">ÇIKIŞ YAP</Button>
                :
                <Link to={RoutePaths.LOGIN}><Button variant="primary btn-lg ml-4">GİRİŞ YAP</Button></Link>
              }
            </div>
            <div className="d-md-none d-flex justify-content-center align-items-center">
              <Link to={RoutePaths.CHAT}><i className="fas fa-2x fa-envelope brown-2 position-relative">
                {mobileBadge}
              </i></Link>
              <Dropdown drop="left" className="d-inline">
                <Dropdown.Toggle variant="menu">
                  <i className="fas fa-ellipsis-v"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>

                  {user && <Dropdown.Item href={RoutePaths.ME}>{user.firstname} {user.lastname}</Dropdown.Item>}

                  {user ?
                    <Dropdown.Item onClick={this.handleLogoutClick}>Çıkış Yap</Dropdown.Item>
                    :
                    <Dropdown.Item href={RoutePaths.LOGIN}>Giriş Yap</Dropdown.Item>
                  }
                  <Dropdown.Divider />
                  <Dropdown.Item href={RoutePaths.WHO_WE_ARE}>Kimler Bizden</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const {rootReducer: {user, messages}} = state;

  return {
    user,
    messages,
  };
};

export default connect(mapStateToProps)(withRouter(Header));

import React, {Component} from "react";
import {Row, Col, Button, Form} from "react-bootstrap";

import "./ChangePassword.scss";
import actions from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";
import {connect} from "react-redux";
import RoutePaths from "../../../constants/RoutePaths";

class ChangePassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      password: "",
      confirmPassword: "",
      showModal: false,
      modalContent: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {changePasswordSuccess, changePasswordError} = nextProps;

    if(changePasswordSuccess) {
      this.setState({
        showModal: true,
        modalContent: "Şifreniz başarıyla değiştirildi."
      });
    }

    if(changePasswordError) {
      this.setState({
        showModal: true,
        modalContent: "Şifre değiştirme işlemi başarısız."
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const {dispatch} = this.props;
    const {password, oldPassword, confirmPassword} = this.state;
    const {user} = this.props;

    let modalContent = "";

    if(oldPassword !== "" && confirmPassword !== "" &&  password !== "" && password === confirmPassword) {
      dispatch(actions.changePassword(user.email, password, oldPassword));
    } else {
      modalContent = "Şifreleriniz Eşleşmiyor. Lütfen tekrar deneyin.";
    }

    this.setState({
      showModal: true,
      modalContent
    });
  }

  handleModalClose() {
    const {history} = this.props;

    if(this.props.changePasswordSuccess) {
      history.push(RoutePaths.ME);
    }

    this.setState({
      showModal: false
    });
  }

  handleCancelButton() {
    history.back();
  }

  render() {
    const {password, oldPassword, confirmPassword, showModal, modalContent} = this.state;

    return(
      <React.Fragment>
        <Modal show={showModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center pl-4 w-100"><i className="fas fa-3x fa-envelope red" /></Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">{modalContent}</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button onClick={() => this.handleModalClose()} variant="outline-primary">
              Tamam
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-center">
          <Col sm={12} md={4}>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group>
                <Form.Label>Eski Şifre:</Form.Label>
                <Form.Control value={oldPassword} onChange={this.handleInputChange} name="oldPassword" type="password" placeholder="Eski Şifre" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Yeni Şifre:</Form.Label>
                <Form.Control value={password} onChange={this.handleInputChange} name="password" type="password" placeholder="Yeni Şifre" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Yeni Şifre Tekrar:</Form.Label>
                <Form.Control value={confirmPassword} onChange={this.handleInputChange} name="confirmPassword" type="password" placeholder="Yeni Şifre Tekrar" />
              </Form.Group>
              <Form.Group>
                <Button variant="primary btn-lg" type="submit">
                  KAYDET
                </Button>
                <Button onClick={this.handleCancelButton} variant="outline-primary btn-lg ml-4" type="submit">
                  VAZGEÇ
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {

  const {rootReducer: {user, changePasswordSuccess, changePasswordError}} = state;

  return {
    user,
    changePasswordSuccess,
    changePasswordError
  };
};


export default connect(mapStateToProps)(ChangePassword);

export const ActionTypes = {
  GET_TEXTS: "GET_TEXTS",
  GET_TEXTS_FAILED: "GET_TEXTS_FAILED",
  GET_TEXTS_FULFILLED: "GET_TEXTS_FULFILLED",
  RESET_HOME_SCREEN: "RESET_HOME_SCREEN",
  RESET_CHAT: "RESET_CHAT",
  RESET_SELECTED_USER: "RESET_SELECTED_USER",
  RESET_RELATED_USERS: "RESET_RELATED_USERS",
  GET_PROFESSIONS: "GET_PROFESSIONS",
  GET_PROFESSIONS_FAILED: "GET_PROFESSIONS_FAILED",
  GET_PROFESSIONS_FULFILLED: "GET_PROFESSIONS_FULFILLED",
  GET_CITY_SUGGESTIONS: "GET_CITY_SUGGESTIONS",
  GET_CITY_SUGGESTIONS_FAILED: "GET_CITY_SUGGESTIONS_FAILED",
  GET_CITY_SUGGESTIONS_FULFILLED: "GET_CITY_SUGGESTIONS_FULFILLED",
  GET_USERS: "GET_USERS",
  GET_USERS_FAILED: "GET_USERS_FAILED",
  GET_USERS_FULFILLED: "GET_USERS_FULFILLED",
  GET_RELATED_USERS: "GET_RELATED_USERS",
  GET_RELATED_USERS_FAILED: "GET_RELATED_USERS_FAILED",
  GET_RELATED_USERS_FULFILLED: "GET_RELATED_USERS_FULFILLED",
  GET_USER: "GET_USER",
  GET_USER_FAILED: "GET_USER_FAILED",
  GET_USER_FULFILLED: "GET_USER_FULFILLED",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_FAILED: "DELETE_USER_FAILED",
  DELETE_USER_FULFILLED: "DELETE_USER_FULFILLED",
  POST_LOGOUT: "POST_LOGOUT",
  POST_LOGOUT_FAILED: "POST_LOGOUT_FAILED",
  POST_LOGOUT_FULFILLED: "POST_LOGOUT_FULFILLED",
  POST_LOGIN: "POST_LOGIN",
  POST_LOGIN_FULFILLED: "POST_LOGIN_FULFILLED",
  POST_LOGIN_FAILED: "POST_LOGIN_FAILED",
  POST_REGISTER: "POST_REGISTER",
  POST_REGISTER_FULFILLED: "POST_REGISTER_FULFILLED",
  POST_REGISTER_FAILED: "POST_REGISTER_FAILED",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  RE_AUTHENTICATE: "RE_AUTHENTICATE",
  RE_AUTHENTICATE_FULFILLED: "RE_AUTHENTICATE_FULFILLED",
  RE_AUTHENTICATE_FAILED: "RE_AUTHENTICATE_FAILED",
  NEXT_QUOTE: "NEXT_QUOTE",
  PREVIOUS_QUOTE: "PREVIOUS_QUOTE",
  ROUTE_PROFILE: "ROUTE_PROFILE",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  FORGET_PASSWORD_FULFILLED: "FORGET_PASSWORD_FULFILLED",
  FORGET_PASSWORD_FAILED: "FORGET_PASSWORD_FAILED",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_FULFILLED: "CHANGE_PASSWORD_FULFILLED",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  POST_REPORT: "POST_REPORT",
  POST_REPORT_FULFILLED: "POST_REPORT_FULFILLED",
  POST_REPORT_FAILED: "POST_REPORT_FAILED",

  BLOCK_USER: "BLOCK_USER",
  BLOCK_USER_FAILED: "BLOCK_USER_FAILED",
  BLOCK_USER_FULFILLED: "BLOCK_USER_FULFILLED",

  UNBLOCK_USER: "UNBLOCK_USER",
  UNBLOCK_USER_FAILED: "UNBLOCK_USER_FAILED",
  UNBLOCK_USER_FULFILLED: "UNBLOCK_USER_FULFILLED",

  ADD_MESSAGE_TO_STORE: "ADD_MESSAGE_TO_STORE",
  ADD_MESSAGE_USER_TO_STORE: "ADD_MESSAGE_USER_TO_STORE",
  PATCH_MESSAGE_IN_STORE: "PATCH_MESSAGE_IN_STORE",

  GET_MESSAGES: "GET_MESSAGES",
  GET_MESSAGES_FULFILLED: "GET_MESSAGES_FULFILLED",
  GET_MESSAGES_FAILED: "GET_MESSAGES_FAILED",

  GET_MESSAGES_OF_USER: "GET_MESSAGES_OF_USER",
  GET_MESSAGES_OF_USER_FULFILLED: "GET_MESSAGES_OF_USER_FULFILLED",
  GET_MESSAGES_OF_USER_FAILED: "GET_MESSAGES_OF_USER_FAILED",

  SEND_MESSAGE: "SEND_MESSAGE",
  PATCH_MESSAGE: "PATCH_MESSAGE",

  DELETE_MESSAGES: "DELETE_MESSAGES",
  DELETE_MESSAGES_FULFILLED: "DELETE_MESSAGES_FULFILLED",
  DELETE_MESSAGES_FAILED: "DELETE_MESSAGES_FAILED",

  SAVE_SEARCH_STATE: "SAVE_SEARCH_STATE",
  PAGE_REFRESH: "PAGE_REFRESH",

  GET_CITIES_AND_COUNTRIES_BY_QUERY: "GET_CITIES_AND_COUNTRIES_BY_QUERY",
  GET_CITIES_AND_COUNTRIES_BY_QUERY_FULFILLED: "GET_CITIES_AND_COUNTRIES_BY_QUERY_FULFILLED",
  GET_CITIES_AND_COUNTRIES_BY_QUERY_FAILED: "GET_CITIES_AND_COUNTRIES_BY_QUERY_FAILED",
};

const saveSearchState = (cityId, professionId, professionGroupId) => ({
  type: ActionTypes.SAVE_SEARCH_STATE,
  payload: {
    cityId,
    professionId,
    professionGroupId
  }
});


const resetChat = () => ({
  type: ActionTypes.RESET_CHAT
});

const resetHomeScreen = () => ({
  type: ActionTypes.RESET_HOME_SCREEN
});

const resetRelatedUsers = () => ({
  type: ActionTypes.RESET_RELATED_USERS
});


const resetSelectedUser = () => ({
  type: ActionTypes.RESET_SELECTED_USER
});

const routeProfile = (selectedUser, history) => ({
  type: ActionTypes.ROUTE_PROFILE,
  payload: {
    selectedUser,
    history
  }
});


const getTexts = () => ({
  type: ActionTypes.GET_TEXTS
});

const getTextsFulfilled = (texts) => ({
  type: ActionTypes.GET_TEXTS_FULFILLED,
  payload: {
    texts
  }
});

const getTextsFailed = () => ({
  type: ActionTypes.GET_TEXTS_FAILED,
});

const getCitySuggestions = (query) => ({
  type: ActionTypes.GET_CITY_SUGGESTIONS,
  payload: {
    query
  }
});

const getCitySuggestionsFulfilled = (cities) => ({
  type: ActionTypes.GET_CITY_SUGGESTIONS_FULFILLED,
  payload: {
    cities
  }
});

const getCitySuggestionsFailed = () => ({
  type: ActionTypes.GET_CITY_SUGGESTIONS_FAILED,
});


const nextQuote = () => ({
  type: ActionTypes.NEXT_QUOTE
});

const previousQuote = () => ({
  type: ActionTypes.PREVIOUS_QUOTE
});


const reAuthenticate = () => ({
  type: ActionTypes.RE_AUTHENTICATE,
});

const reAuthenticateFulfilled = (authResult) => ({
  type: ActionTypes.RE_AUTHENTICATE_FULFILLED,
  payload: {
    authResult
  }
});

const reAuthenticateFailed = (error) => ({
  type: ActionTypes.RE_AUTHENTICATE_FAILED,
  payload: {
    error
  }
});

const blockUser = (userId) => ({
  type: ActionTypes.BLOCK_USER,
  payload: {
    userId
  }
});

const blockUserFulfilled = (result) => ({
  type: ActionTypes.BLOCK_USER_FULFILLED,
  payload: {
    toUser: result.toUser
  }
});

const blockUserFailed = (error) => ({
  type: ActionTypes.BLOCK_USER_FAILED,
  payload: {
    error
  }
});

const unBlockUser = (userId) => ({
  type: ActionTypes.UNBLOCK_USER,
  payload: {
    userId
  }
});

const unBlockUserFulfilled = (result) => ({
  type: ActionTypes.UNBLOCK_USER_FULFILLED,
  payload: {
    result
  }
});

const unBlockUserFailed = (error) => ({
  type: ActionTypes.UNBLOCK_USER_FAILED,
  payload: {
    error
  }
});

const postLogout = () => ({
  type: ActionTypes.POST_LOGOUT,
});

const postLogoutFulfilled = () => ({
  type: ActionTypes.POST_LOGIN_FULFILLED,
});

const postLogoutFailed = () => ({
  type: ActionTypes.POST_LOGOUT_FAILED,
});


const postReport = (userId, values) => ({
  type: ActionTypes.POST_REPORT,
  payload: {
    userId, values
  }
});

const postReportFulfilled = (result) => ({
  type: ActionTypes.POST_REPORT_FULFILLED,
  payload: {
    result
  }
});

const postReportFailed = (error) => ({
  type: ActionTypes.POST_REPORT_FAILED,
  payload: {
    error
  }
});

const postLogin = (email, password) => ({
  type: ActionTypes.POST_LOGIN,
  payload: {
    email, password
  }
});

const postLoginFulfilled = (authResult) => ({
  type: ActionTypes.POST_LOGIN_FULFILLED,
  payload: {
    authResult
  }
});

const postLoginFailed = (error) => ({
  type: ActionTypes.POST_LOGIN_FAILED,
  payload: {
    error
  }
});

const deleteUser = (id) => ({
  type: ActionTypes.DELETE_USER,
  payload: {
    id
  }
});

const deleteUserFulfilled = (user) => ({
  type: ActionTypes.DELETE_USER_FULFILLED,
  payload: {
    user
  }
});

const deleteUserFailed = (error) => ({
  type: ActionTypes.DELETE_USER_FAILED,
  payload: {
    error
  }
});

const getUser = (id) => ({
  type: ActionTypes.GET_USER,
  payload: {
    id
  }
});

const getUserFulfilled = (user) => ({
  type: ActionTypes.GET_USER_FULFILLED,
  payload: {
    user
  }
});

const getUserFailed = (error) => ({
  type: ActionTypes.GET_USER_FAILED,
  payload: {
    error
  }
});


const getUsers = (cityId, countryId, professionId, skip) => ({
  type: ActionTypes.GET_USERS,
  payload: {
    cityId, countryId, professionId, skip
  }
});

const getUsersFulfilled = (result) => ({
  type: ActionTypes.GET_USERS_FULFILLED,
  payload: {
    result
  }
});

const getUsersFailed = (error) => ({
  type: ActionTypes.GET_USERS_FAILED,
  payload: {
    error
  }
});


const getRelatedUsers = (cityId, countryId, professionId, professionGroupId) => ({
  type: ActionTypes.GET_RELATED_USERS,
  payload: {
    cityId, countryId, professionId, professionGroupId
  }
});

const getRelatedUsersFulfilled = (result) => ({
  type: ActionTypes.GET_RELATED_USERS_FULFILLED,
  payload: {
    result
  }
});

const getRelatedUsersFailed = (error) => ({
  type: ActionTypes.GET_RELATED_USERS_FAILED,
  payload: {
    error
  }
});

const getProfessions = () => ({
  type: ActionTypes.GET_PROFESSIONS,
});

const getProfessionsFulfilled = (professions) => ({
  type: ActionTypes.GET_PROFESSIONS_FULFILLED,
  payload: {
    professions
  }
});

const getProfessionsFailed = (error) => ({
  type: ActionTypes.GET_PROFESSIONS_FAILED,
  payload: {
    error
  }
});


const postRegister = (firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId) => ({
  type: ActionTypes.POST_REGISTER,
  payload: {
    firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId
  }
});

const postRegisterFulfilled = (authResult) => ({
  type: ActionTypes.POST_REGISTER_FULFILLED,
  payload: {
    authResult
  }
});

const postRegisterFailed = (error) => ({
  type: ActionTypes.POST_REGISTER_FAILED,
  payload: {
    error
  }
});


const updateUser = (id, firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId, isEmailAlertActive) => ({
  type: ActionTypes.UPDATE_USER,
  payload: {
    id, firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId, isEmailAlertActive
  }
});

const updateUserFulfilled = (user) => ({
  type: ActionTypes.UPDATE_USER_FULFILLED,
  payload: {
    user
  }
});

const updateUserFailed = (error) => ({
  type: ActionTypes.UPDATE_USER_FAILED,
  payload: {
    error
  }
});

const forgetPassword= (email) => ({
  type: ActionTypes.FORGET_PASSWORD,
  payload: {
    email
  }
});

const forgetPasswordFulfilled = (authResult) => ({
  type: ActionTypes.FORGET_PASSWORD_FULFILLED,
  payload: {
    authResult
  }
});

const forgetPasswordFailed = (error) => ({
  type: ActionTypes.FORGET_PASSWORD_FAILED,
  payload: {
    error
  }
});


const changePassword = (email, password, oldPassword) => ({
  type: ActionTypes.CHANGE_PASSWORD,
  payload: {
    email,
    password,
    oldPassword
  }
});

const changePasswordFulfilled = () => ({
  type: ActionTypes.CHANGE_PASSWORD_FULFILLED,
});

const changePasswordFailed = (error) => ({
  type: ActionTypes.CHANGE_PASSWORD_FAILED,
  payload: {
    error
  }
});

const addMessageToStore = (message) => ({
  type: ActionTypes.ADD_MESSAGE_TO_STORE,
  payload: {
    message
  }
});

const addMessageUserToStore = (toUser) => ({
  type: ActionTypes.ADD_MESSAGE_USER_TO_STORE,
  payload: {
    toUser
  }
});

const patchMessageInStore = (message) => ({
  type: ActionTypes.PATCH_MESSAGE_IN_STORE,
  payload: {
    message
  }
});

const getMessages = (clearEmpty) => ({
  type: ActionTypes.GET_MESSAGES,
  payload: {
    clearEmpty,
  }
});

const getMessagesFulfilled = (messages, clearEmpty) => ({
  type: ActionTypes.GET_MESSAGES_FULFILLED,
  payload: {
    messages,
    clearEmpty,
  }
});

const getMessagesFailed = (error) => ({
  type: ActionTypes.GET_MESSAGES_FAILED,
  payload: {
    error,
  }
});

const getMessagesOfUser = (uid) => ({
  type: ActionTypes.GET_MESSAGES_OF_USER,
  payload: {
    uid,
  }
});

const getMessagesOfUserFulfilled = (messages, uid) => ({
  type: ActionTypes.GET_MESSAGES_OF_USER_FULFILLED,
  payload: {
    messages,
    uid,
  }
});

const getMessagesOfUserFailed = (error) => ({
  type: ActionTypes.GET_MESSAGES_OF_USER_FAILED,
  payload: {
    error,
  }
});

const sendMessage = (message, toUserId) => ({
  type: ActionTypes.SEND_MESSAGE,
  payload: {
    message,
    toUserId,
  }
});

const patchMessage = (message, userId) => ({
  type: ActionTypes.PATCH_MESSAGE,
  payload: {
    message,
    userId,
  }
});

const deleteMessages = (...userIds) => ({
  type: ActionTypes.DELETE_MESSAGES,
  payload: {
    userIds,
  }
});

const deleteMessagesFulfilled = (...userIds) => ({
  type: ActionTypes.DELETE_MESSAGES_FULFILLED,
  payload: {
    userIds,
  }
});

const deleteMessagesFailed = (error) => ({
  type: ActionTypes.DELETE_MESSAGES_FAILED,
  payload: {
    error,
  }
});

const pageRefresh = (pageRefresh) => ({
  type: ActionTypes.PAGE_REFRESH,
  payload: {
    pageRefresh,
  }
});

export default {
  nextQuote,
  previousQuote,
  postLogin,
  postLoginFulfilled,
  postLoginFailed,
  reAuthenticate,
  reAuthenticateFulfilled,
  reAuthenticateFailed,
  postLogout,
  postLogoutFulfilled,
  postLogoutFailed,
  getCitySuggestions,
  getCitySuggestionsFulfilled,
  getCitySuggestionsFailed,
  postRegister,
  postRegisterFulfilled,
  postRegisterFailed,
  getUsers,
  getUsersFulfilled,
  getUsersFailed,
  getUser,
  getUserFulfilled,
  getUserFailed,
  deleteUser,
  deleteUserFulfilled,
  deleteUserFailed,
  routeProfile,
  forgetPassword,
  forgetPasswordFulfilled,
  forgetPasswordFailed,
  changePassword,
  changePasswordFulfilled,
  changePasswordFailed,
  getProfessions,
  getProfessionsFulfilled,
  getProfessionsFailed,
  updateUser,
  updateUserFulfilled,
  updateUserFailed,
  getRelatedUsers,
  getRelatedUsersFulfilled,
  getRelatedUsersFailed,
  resetHomeScreen,
  resetSelectedUser,
  postReport,
  postReportFailed,
  postReportFulfilled,
  blockUser,
  blockUserFailed,
  blockUserFulfilled,
  unBlockUser,
  unBlockUserFailed,
  unBlockUserFulfilled,
  getTexts,
  getTextsFulfilled,
  getTextsFailed,
  resetChat,
  resetRelatedUsers,

  addMessageToStore,
  addMessageUserToStore,
  patchMessageInStore,

  getMessages,
  getMessagesFulfilled,
  getMessagesFailed,

  getMessagesOfUser,
  getMessagesOfUserFulfilled,
  getMessagesOfUserFailed,

  sendMessage,
  patchMessage,
  deleteMessages,
  deleteMessagesFulfilled,
  deleteMessagesFailed,

  saveSearchState,
  pageRefresh,
};

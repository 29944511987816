import feathers from "@feathersjs/feathers";
import auth from "@feathersjs/authentication-client";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
import axios from "axios";

import actions from "../redux/actions";
import {capitalize} from "../utils/stringUtils";

const development = process.env.NODE_ENV === "development";

const socketPath = development ? "http://localhost:3000" : "https://bizdenkimvar.com";

const socket = io(socketPath);

export const app = feathers();
app.configure(socketio(socket));
app.configure(auth());

let _store;

const Api = {
  setStore(store) {
    _store = store;
  },

  login: (email, password) => {
    return new Promise((resolve, reject) => {
      socket.emit("create", "authentication", {
        strategy: "local",
        email,
        password
      }, function (error, authResult) {
        if (error) {
          Api.stopListeningForMessages();
          reject(error);
        } else {
          app.authentication.setAccessToken(authResult.accessToken);
          Api.startListeningForMessages();
          resolve(authResult);
        }
      });
    });
  },

  logout: () => {
    Api.stopListeningForMessages();
    return app.logout();
  },

  reAuthenticate: () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const accessToken = await app.authentication.getAccessToken();

      if (!accessToken) {
        Api.stopListeningForMessages();
        return reject();
      }

      try {
        const result = await app.authenticate({ strategy: "jwt", accessToken });
        Api.startListeningForMessages();
        resolve(result);
      } catch (error) {
        if (error.code === 401 || error.code === 404) {
          await app.authentication.removeAccessToken();
        }

        Api.stopListeningForMessages();
        reject(error);
      }
    });
  },

  getUsers: (cityId, countryId, professionId, professionGroupId, skip, limit = 30) => {
    return (async() => {
      const userService = app.service("users");

      const query = {};
      if (cityId) query.city_id = cityId;

      if (countryId) {
        console.log("-> countryId", countryId);

        const citiesService = app.service("cities");
        const cities = await citiesService.find({
          query: {
            country_id: countryId,
          },
        });

        console.log("-> cities", cities);

        query.city_id = {
          $in: cities.map((c) => c.id),
        };
      }

      if (professionId) query.profession_id = professionId;

      if (professionGroupId) {
        query.profession_id = { $ne: professionId };
      }

      if (skip) {
        query.$skip = skip;
      }

      if (professionGroupId) query.profession_group_id = professionGroupId;

      console.log("-> query", query);

      return userService.find({
        query: {
          ...query,
          $limit: limit,
          $sort: {
            isOnline: -1,
            createdAt: 1
          }
        }
      });
    })();
  },

  getUser: (id) => {
    const userService = app.service("users");
    return userService.get(id);
  },

  deleteUser: (id) => {
    const userService = app.service("users");
    return userService.remove(id);
  },

  updateUser: (id, firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId, isEmailAlertActive) => {
    const cityService = app.service("users");

    let payload = {
      city_id: cityId ? cityId : null,
      profession_id: professionId ? professionId : null,
      profession_group_id: professionGroupId ? professionGroupId : null,
      description,
      isEmailAlertActive
    };

    if (image !== "") {
      payload.image = image;
    }

    return cityService.patch(id, payload);
  },

  getCitiesAndCountriesByQuery: (query) => {
    return new Promise(async (resolve, reject) => {
      const countryService = app.service("countries");
      countryService.timeout = 60000;

      const cityService = app.service("cities");
      cityService.timeout = 60000;

      try {
        const countries = await countryService.find({
          query: {
            $or: [
              {
                name: {
                  $like: `%${query}%`,
                },
              },
              {
                name_en: {
                  $like: `%${query}%`,
                },
              },
            ],
          },
        });

        let citySearchParams = {
          query: {
            $or: [
              {
                name_tr: {
                  $like: `%${query}%`,
                },
              },
              {
                name_en: {
                  $like: `%${query}%`,
                },
              },
            ],
            $sort: {
              country_id: 1,
              id: 1,
            },
          },
        };

        const countryIds = countries.map((c) => c.id);

        if (countryIds.length) {
          citySearchParams["query"]["$or"].push({
            country_id: {
              $in: countryIds,
            },
          });
        }

        const cities = await cityService.find(citySearchParams);

        resolve({ countries, cities });
      } catch (error) {
        reject(error);
      }
    });
  },

  getCitySuggestions: (query) => {
    const cityService = app.service("cities");
    return cityService.find({
      query: {
        $or: [
          {
            name_tr: {
              $like: query + "%",
            },
          },
          {
            name_en: {
              $like: query + "%",
            },
          },
        ],
      }
    });
  },

  register: (firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId) => {
    const userService = app.service("users");

    firstname = capitalize(firstname);
    lastname = capitalize(lastname);

    let payload = {
      firstname,
      lastname,
      email,
      password,
      description,
      isVisible
    };

    if(cityId) {
      payload.city_id = cityId;
    }

    if(professionId) {
      payload.profession_id = professionId;
    }

    if(professionGroupId) {
      payload.profession_group_id = professionGroupId;
    }

    if(image) {
      payload.image = image;
    }

    return userService.create(payload);
  },

  postReport: (userId, values) => {
    const userService = app.service("reports");
    let payload = {
      to_user_id: userId,
      values
    };

    return userService.create(payload);
  },

  blockUser: (userId) => {
    const userService = app.service("blocked_users");
    let payload = {
      to_user_id: userId,
    };

    return userService.create(payload);
  },

  unBlockUser: (userId) => {
    const userService = app.service("blocked_users");

    return userService.remove(null, { query: { to_user_id: userId } });
  },

  resetPassword: (email) => {
    const service = app.service("authManagement");
    return service.create({
      action: "sendResetPwd",
      value: {
        email
      }
    });
  },

  changePassword: (email, oldPassword, password) => {
    const service = app.service("authManagement");
    return service.create({
      action: "passwordChange",
      value: {
        user: {
          email
        },
        oldPassword,
        password
      }
    });
  },

  authmanagement: (action, value) => {
    let payload = {
      action,
      value
    };

    return axios.post("/api/v1/authManagement", payload);
  },

  getProfessions: () => {
    const service = app.service("professions");
    return service.find(
      {
        query: {
          $sort: {
            group_id: 1,
            id: 1,
          }
        }
      }
    );
  },

  getTexts: () => {
    const service = app.service("texts");
    return service.find();
  },

  startListeningForMessages: () => {
    console.info("Started listening for messages");
    app.service("messages").on("created", (message) => {
      _store.dispatch(actions.addMessageToStore(message));
    });
    app.service("messages").on("patched", (message) => {
      _store.dispatch(actions.patchMessageInStore(message));
    });
  },

  stopListeningForMessages: () => {
    console.info("Listening for messages stopped");
    app.service("messages").off("created");
    app.service("messages").off("patched");
  },

  getMessages: () => {
    const service = app.service("chats");
    service.timeout = 60000;

    return service.find();
  },

  getMessagesOfUser: (uid) => {
    const service = app.service("messages");
    service.timeout = 60000;

    return service.find({
      query: {
        $or: {
          from_user_id: uid,
          to_user_id: uid,
        },
        $sort: {
          id: -1
        },
      }
    });
  },

  sendMessage: (message, toUserId) => {
    const service = app.service("messages");
    return service.create({
      message,
      to_user_id: toUserId,
    });
  },

  patchMessage: (message, userId) => {
    const service = app.service("messages");
    return service.patch(message.id, {
      [userId === message.from_user_id ? "from_is_read" : "to_is_read"]: 1,
    });
  },

  deleteMessages: (userIds) => {
    const service = app.service("messages");
    return Promise.all([
      service.patch(null, {
        from_is_deleted: 1,
      }, {
        query: {
          from_user_id: userIds[1],
          to_user_id: userIds[0],
          from_is_deleted: 0,
        },
      }),

      service.patch(null, {
        to_is_deleted: 1,
      }, {
        query: {
          from_user_id: userIds[0],
          to_user_id: userIds[1],
          to_is_deleted: 0,
        },
      }),
    ]);
  }
};

export default Api;

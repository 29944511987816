const accentFold = (inStr) => {
  return inStr.replace(
    /([àáâãäå])|([çÇ])|([èéêë])|([ìíîïıİ])|([ñ])|([òóôõöøÖ])|([ßşŞ])|([ùúûü])|([ÿ])|([æ])|([ğĞ])/g,
    function (str, a, c, e, i, n, o, s, u, y, ae, g) {
      if (a) return "a";
      if (c) return "c";
      if (e) return "e";
      if (i) return "i";
      if (n) return "n";
      if (o) return "o";
      if (s) return "s";
      if (u) return "u";
      if (y) return "y";
      if (ae) return "ae";
      if (g) return "g";
    }
  ).toLocaleLowerCase("tr");
};

const customProfessionFilter = (option, searchText) => {
  const searchTerm = accentFold(option.data.label);
  return searchTerm.includes(accentFold(searchText));
};

const capitalize = (word) => {
  const lowerCaseAll = word.toLocaleLowerCase("tr");
  return lowerCaseAll.replace(/(?:^|\s)\S/g, function(a) { return a.toLocaleUpperCase("tr"); });
};


export {
  accentFold,
  customProfessionFilter,
  capitalize
};


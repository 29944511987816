import * as React from "react";
import {Row, Col, InputGroup, Button, FormControl} from "react-bootstrap";

import {SearchResults} from "../../parts";

import Logo from "../../../assets/images/logo-red.svg";
import LogoTitle from "../../../assets/images/logo-title.png";
import "./Search.scss";


function Search() {
  return (
    <main className="Search">
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-center align-items-end flex-wrap">
            <img alt="Logo" src={Logo} />
            <img className="logo-title ml-4 mt-4 mt-md-0" alt="Logo Title" src={LogoTitle} />
          </div>
        </Col>
        <Col>
          <div className="mt-5 d-flex justify-content-center">
            <div className="explain text-center">
              Dünyanın tüm şehirlerindeki dişçilerimizden mimarlarımıza, fotoğrafçılarımızdan restoran işletmecilerimize kadar bizden kim varsa burada! Maksat doğru insanların beraber iş yapabilmesi
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InputGroup className="mt-5 mb-3">
            <FormControl placeholder="Bu şehirden"
            />
            <InputGroup.Append>
              <Button variant="outline-secondary">Ara</Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col md={6}>
          <InputGroup className="mb-3 mt-md-5">
            <FormControl placeholder="Bu meslekten"
            />
            <InputGroup.Append>
              <Button variant="outline-secondary">Ara</Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col md={12}>
          <div className="d-flex justify-content-center my-5">
            <Button variant="primary btn-lg">BİZDEN KİM VAR?</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-center my-3 status-container">
            Bizden<span className="count mx-3">10</span>kişi var
          </div>
        </Col>
      </Row>
      <SearchResults/>
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-center my-3 status-container">
            İlişkili mesleklerden bizden<span className="count mx-3">30</span>kişi var
          </div>
        </Col>
      </Row>
      <SearchResults/>
    </main>
  );
}

export default Search;

import React, {Component} from "react";
import {Row, Col, Button, Form} from "react-bootstrap";
import {connect} from "react-redux";

import "./Report.scss";
import actions from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import RoutePaths from "../../../constants/RoutePaths";

class Report extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      reportText1: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    this.userId = this.props.match.params.id;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();

    let values = "# ";
    let inputs = event.target;


    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i];
      if (input.checked) {
        values += input.value + " # ";
      }
    }

    if (values.length < 3) {
      return;
    }


    const {dispatch} = this.props;
    dispatch(actions.postReport(this.userId, values));

    this.setState({
      showModal: true
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false
    });

    this.props.history.push(RoutePaths.HOME);
  }

  render() {
    const {showModal} = this.state;

    return(
      <React.Fragment>
        <Modal show={showModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100"><i className="fas fa-3x fa-envelope red" /></Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">Talebiniz alınmıştır. En kısa zamanda sizi bilgilendireceğiz.</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button onClick={() => this.handleModalClose()} variant="outline-primary">
              Tamam
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-center">
          <Col sm={12} md={6}>
            <div className="login-top-nav">
              <div>Şikayet talebinizi belirtiniz</div>
            </div>
            <div className="login-card">
              <Form onSubmit={this.handleFormSubmit}>
                <Form.Group>
                  <Form.Check type="checkbox" value="Gerçek şahış değil." label="Gerçek şahış değil." name="" onChange={this.handleInputChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Check type="checkbox" value="18 yaşından küçük profil/sözleşme kriterlerinin dışında." label="18 yaşından küçük profil/sözleşme kriterlerinin dışında." name="" onChange={this.handleInputChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Check type="checkbox" value="Profilimi taklit ediyor." label="Profilimi taklit ediyor." name="" onChange={this.handleInputChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Check type="checkbox" value="İstenmeyen mesajlar alıyorum." label="İstenmeyen mesajlar alıyorum." name="" onChange={this.handleInputChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Check type="checkbox" value="Uygunsuz profil fotoğrafı ve/veya açıklama." label="Uygunsuz profil fotoğrafı ve/veya açıklama." name="" onChange={this.handleInputChange} />
                </Form.Group>
                <Form.Group className="text-center">
                  <Button variant="primary btn-lg mt-5" type="submit">
                    Şikayet Et
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect()(Report);

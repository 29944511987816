import * as React from "react";
import { Col, Row} from "react-bootstrap";

import "./Information.scss";

function Information() {
  return (
    <main className="information">
      <Row className="justify-content-center">
        <Col sm={12} md={8}>
          <h1 className="text-center mb-5">Son bir adım…</h1>
          <p className="text-center">Üyeliğini silme talebin email adresine yollandı. Emailine girip onay vermen durumunda üyeliğin tamamen silinecektir.</p>
          <p className="text-center">Yeniden aramızda olman dileği ile…</p>
          <div className="help-title">
            <i className="fas fa-2x fa-question-circle" />
            <span>Sorun mu var? Bize <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a> adresinden ulaşabilirsin.</span>
          </div>
        </Col>
      </Row>
    </main>
  );
}

export default Information;

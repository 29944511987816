import * as React from "react";
import {Row, Col} from "react-bootstrap";

import "./WhoWeAre.scss";
import SceneImage from "../../../assets/images/who-we-are-scene.jpg";

function WhoWeAre() {
  return (
    <main className="who-we-are">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1>Kimler <br />Bizden?</h1>
          <p className="mt-4">
            Dünyanın neresinde ve ne is yapıyor olursa olsun, hakkını vererek yapan, basari ve gelişimini devamlılık ilkesine oturtmuş, doğadaki her canlının yasam haklarına saygılı, ülkemiz insanlarının hepsine <b>“bizden”</b> denir.
          </p>
          <p>
            Bununla beraber müşterilerinin ve çalışma arkadaşlarının benimsediği değerlere sahip bireylerden oluşmasını amaçlayan herkese de <b>“bizden”</b> denir.
          </p>
          <p>
            Şimdi bize katılarak; tüm dünyadaki üyelerle direk iletişime geçebilir, fikir danışabilir, beraber is yapabilir, aynı zamanda işlerinde sana ihtiyaç duyanların sana direk ulaşabilmelerini sağlayabilirsin.
          </p>
          <p>
            <b>Maksat doğru insanların beraber is yapabilmesi.</b>
          </p>
          <p>
            <b>Her türlü eleştiri ve şikâyetinde <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a> email hattı 7/24 buralarda olacak.</b>
          </p>
          <img className="w-100 mt-5" src={SceneImage} alt="Who We Are Scene"/>
        </Col>
      </Row>
    </main>
  );
}

export default WhoWeAre;

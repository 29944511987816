import RoutePaths from "./RoutePaths";

const disclaimers = [
  {
    version: "",
    message: 'Hoş geldin!\n\nBizdenkimvar.com destek ekibi olarak site kullanımı hakkında sana bazı faydalı ipuçları vermek istiyoruz;\n\n1- Arama sonuçları kayıt tarihine göre sıralanmaktadır. Arama sonuçlarında ilk sayfada çıkmak için çevrimiçi olman gerekir.\n2- Arama yaparken: Şehir bilgisini seçtikten sonra, meslek seçeneğini boş bırakarak da arama yapabilirsin. Böylece o şehirde bizden kim var ise hepsini toplu olarak görebilirsin.\n3- Arama yaparken: Şehir seçmeksizin sadece meslek girerek de arama yapabilirsin. Böylece dünya üzerindeki farklı şehirlerdeki seçtiğin meslek sahibi herkesle direk ulaşım sağlayabilirsin.\n4- Profil fotoğrafı: Sıkça değiştirmemeni öneririz. Böylece arama sonuçlarında göz aşinalığı oluşturarak akılda kalıcı hale gelirsin. Bu devamlılık için önemli sonuçta..\n5- Profilindeki “açıklama” kısmı: Mesleğin ile ilgili en doğru ve anlaşılır bilgileri kısaca girersen kullanıcıların seninle daha doğru şekilde iletişime geçmelerini sağlayabilirsin. Burada kişisel telefon, e-mail veya açık adres paylaşmamanı öneririz\n6- İletişime geçtiğin kişilerle iş yapmadan önce meslek tanımına uygunluğunu, kendilerinin kayıtlı olduğu meslek odalarından öğrenebilirsin. Bunu talep etmekten çekinme.\n7- Her türlü eleştiri veya şikayetini buradan bize yazarak dile getirebilirsin.Hızlıca çözüm bulmaya çalışacağımızdan emin olabilirsin.\n\nİşlerinde kolaylıklar\nBizdenkimvar.com destek ekibi'
  },
];

export { RoutePaths, disclaimers };

import * as React from "react";
import { Col, Row} from "react-bootstrap";

import "./Legal.scss";

function Legal() {
  return (
    <main className="Legal">
      <Row className="justify-content-center">
        <Col sm={12} md={9}>
          <p>
            Bizden herkesin katılabildiği sosyal ağımızda, sorunsuz iletişim akışı sağlama çabasındayız ve bu yüzden de bu sosyal iletişim ağının temiz, güvenli ve yasalara uygun şekilde olmasını istemekteyiz.
          </p>
          <p>Tüm kayıtlı kullanıcıların aşağıda yazan kullanım koşullarını kabul etmeleri gerekmektedir.</p>
          <ul>
            <li>En az 18 yaşında olmak.</li>
            <li>Herhangi bir sosyal ağda kullanıcı hesaplarinin, yasalara veya herhangi bir ilkeyi ihlal etme nedeniyle kapatılmamis olmasi.</li>
            <li>Herhangi bir cinsel suç işlememis ve/veya bu yüzden hüküm giymemiş olmak.</li>
            <li>Başkasının adına kullanıcı oluşturmanın, doğru olmayan bilgiler vermenin sanal suçlar kapsamında değerlendirileceğini</li>
            <li>"Site üzerinden yapilacak görüşmelerin / yazışmaların site yönetimi tarafından her iki tarafın güvenliği açısından kayıt altına alınacağını, şikâyet edildiğinde inceleneceğini kabul etmek."</li>
            <li>Bizdenkimvar yönetiminin hiçbir kullanıcının bilgilerinin doğruluğundan ve/veya üyeler arasında doğan olumlu/olumsuz hiçbir durumdan sorumlu olmadigini kabul etmek.</li>
            <li>Kullanıcı hesabı satisi veya devrinin olmayacagi.</li>
            <li>Fikri mülkiyet haklarını ihlal etme ve başkasının haklarını ihlal edecek herhangi bir eylemde bulunmamak.</li>
            <li>Site yönetiminin profili şikayet durumunda hesaba müdahale etme, tamamen silme ve engelleme hakkı olduğunu kabul etmek.</li>
            <li>Bizdenkimvar sosyal aginda sahte hesap oluşturmamak, başkalarının güvenlik bilgilerine erişmeye çalışmamak, siyasi ve/veya yasadışı herhangi bir eylem gerçekleştirmemek.</li>
            <li>Reklamların ve sponsorlu içeriklerin yanında veya bunlarla bağlantılı olarak gösterimini kabul etmek.</li>

          </ul>
          <div className="text-center">
            <i className="fas fa-question-circle mr-2"/>
            <span>Sorun mu var? Bize <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a> adresinden ulaşabilirsin.</span>
          </div>
        </Col>
      </Row>
    </main>
  );
}

export default Legal;

import React, {Component} from "react";
import {RoutePaths} from "../../../constants";
import api from "../../../services/api";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";

class Verify extends Component {

  constructor(props) {
    super(props);

    this.passwordChangeSuccess = false;

    this.state = {
      password: "",
      confirmPassword: "",
      showModal: false,
      modalContent: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const {action, value} = this.props.match.params;
    const {history} = this.props;

    switch (action) {
      case "verify":
        api.authmanagement("verifySignupLong", value);
        history.push(RoutePaths.WELCOME);
        break;
      case "deleteMyself":
        api.authmanagement("deleteMyself", value);
        history.push(RoutePaths.HOME);
        break;
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const {password, confirmPassword} = this.state;

    const {value} = this.props.match.params;

    if(confirmPassword !== "" && password !== "" && password === confirmPassword) {

      const payloadValue = {
        token: value,
        password: password
      };

      api.authmanagement("resetPwdLong", payloadValue).then(() => {

        this.passwordChangeSuccess = true;

        this.setState({
          showModal: true,
          modalContent: "Şifreniz başarıyla değiştirildi."
        });

      }).catch(() => {

        this.setState({
          showModal: true,
          modalContent: "Şifre değiştirirken hata oluştu."
        });

      });

    } else {

      this.setState({
        showModal: true,
        modalContent: "Şifreleriniz Eşleşmiyor. Lütfen tekrar deneyin."
      });
    }
  }

  handleModalClose() {
    const {history} = this.props;

    if(this.passwordChangeSuccess) {
      history.push(RoutePaths.LOGIN);
    }

    this.setState({
      showModal: false
    });
  }

  render() {
    const {password, confirmPassword, showModal, modalContent} = this.state;

    return(
      <React.Fragment>
        <Modal show={showModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center pl-4 w-100"><i className="fas fa-3x fa-envelope red" /></Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">{modalContent}</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button onClick={() => this.handleModalClose()} variant="outline-primary">
              Tamam
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-center">
          <Col sm={12} md={4}>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group>
                <Form.Label>Yeni Şifre:</Form.Label>
                <Form.Control value={password} onChange={this.handleInputChange} name="password" type="password" placeholder="Yeni Şifre" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Yeni Şifre Tekrar:</Form.Label>
                <Form.Control value={confirmPassword} onChange={this.handleInputChange} name="confirmPassword" type="password" placeholder="Yeni Şifre Tekrar" />
              </Form.Group>
              <Form.Group>
                <Button variant="primary btn-lg" type="submit">
                  KAYDET
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}


export default Verify;

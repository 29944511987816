import React, {Component} from "react";
import {Row, Col, Button, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

import {ProfileCard} from "../../parts";
import "./Profile.scss";
import Select from "react-select";
import api from "../../../services/api";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import actions from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";
import RoutePaths from "../../../constants/RoutePaths";

import {customProfessionFilter} from "../../../utils/stringUtils";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      firstname: "",
      lastname: "",
      userNumber: "",
      email: "",
      description: "",
      isVisible: null,
      profession: {
        label: "",
        value: ""
      },
      city: {
        label: "",
        value: ""
      },
      image: null,
      imageData: "",
      showModal: false,
      modalTitle: "",
      modalContent: "",
      imageChanged: false,
      registerRequired: false,
      professionGroupId: null,
      isEmailAlertActive: false
    };

    this.handleProfessionChange = this.handleProfessionChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.clickMessageHandler = this.clickMessageHandler.bind(this);
    this.blockUserHandler = this.blockUserHandler.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  componentDidMount() {
    const {user, dispatch} = this.props;

    this.isMe = this.props.match.path.indexOf("/me") !== -1;

    const profileId = this.props.match.params.id;

    if (this.isMe) {
      this.updateUser(user);
    } else {
      dispatch(actions.getUser(profileId));
    }

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(actions.resetSelectedUser());
  }

  componentDidUpdate(prevProps) {
    const {updateUserSuccess, selectedUser} = this.props;

    if (selectedUser !== prevProps.selectedUser) {
      this.updateUser(selectedUser);
    }

    if (updateUserSuccess !== prevProps.updateUserSuccess) {
      this.setState({
        showModal: true,
        modalTitle: "Başarılı",
        modalContent: "Bilgilerin değiştirildi."
      });
    }
  }

  updateUser(data) {
    const {id, firstname, lastname, user_number, email, city, profession, description, isVisible, image, profession_group_id, isEmailAlertActive} = data;

    this.setState({
      id,
      firstname,
      lastname,
      description,
      userNumber: user_number,
      email,
      isVisible,
      city: {
        label: city ? city.name_tr : "",
        value: city ? city.id : "",
      },
      profession: {
        label: profession ? profession.name : "",
        value: profession ? profession.id : "",
      },
      image,
      professionGroupId: profession_group_id,
      isEmailAlertActive
    });
  }

  blockUserHandler() {
    const {dispatch, selectedUser} = this.props;

    if (selectedUser.isBlocked) {
      dispatch(actions.unBlockUser(selectedUser.id));
    } else {
      dispatch(actions.blockUser(selectedUser.id));
    }
  }

  clickMessageHandler() {
    const {history, user, selectedUser, dispatch} = this.props;

    if (user === null) {
      localStorage.setItem("newRegisterUserId", selectedUser.id);

      this.setState({
        showModal: true,
        modalContent: "Mesaj gönderebilmen için giriş yapman gerekiyor.",
        registerRequired: true
      });
    } else if (!this.isMe && selectedUser.hasBlockedMe) {
      this.setState({
        showModal: true,
        modalContent: "Kullanıcı tarafından engellendiniz."
      });
    } else if (!this.isMe && selectedUser.isBlocked) {
      this.setState({
        showModal: true,
        modalContent: "Engellediğiniz kullanıcıya mesaj atamazsınız."
      });
    } else if (selectedUser) {
      dispatch(actions.addMessageUserToStore(selectedUser));
      history.push(`/chat/${selectedUser.id}`);
    } else {
      history.push(RoutePaths.CHAT);
    }
  }

  cityLoadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 2) {
      if (inputValue.length > 2) {
        api.getCitySuggestions(inputValue).then((citySuggestions) => {
          const temp = citySuggestions.map((value) => {
            return {
              value: value.id,
              label: value.name_tr + " / " + value.country.name
            };
          });
          callback(temp);
        });
      }
    }
  };

  reactSelectTheme(theme) {
    return(
      {
        ...theme,
        colors: {
          ...theme.colors,
          primary50: "#f8f9fa",
          primary25: "#f8f9fa",
          primary: "#ff2a48",
        },
      }
    );
  }

  handleCityChange(option, action){
    if(action.action === "select-option") {
      const {value} = option;

      this.setState({
        city: {
          value
        },
      });
    }
  }

  handleProfessionChange(option, action){
    if(action.action === "select-option") {
      const {value, groupId} = option;

      this.setState({
        profession: {
          value
        },
        professionGroupId: groupId
      });
    }
  }

  handleImageChange(image) {
    let imageWithoutBase64Header = null;

    if(image !== null) {
      imageWithoutBase64Header = image.split(",")[1];
    }

    this.setState({
      image,
      imageData: imageWithoutBase64Header,
      imageChanged: true
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleCheckBoxChange(event) {
    const target = event.target;
    const value = target.checked;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const {id, firstname, lastname, email, password, city, profession, description, isVisible, imageData, professionGroupId, isEmailAlertActive} = this.state;
    const {dispatch} = this.props;

    dispatch(actions.updateUser(id, firstname, lastname, email, password, city.value, profession.value, description, isVisible, imageData, professionGroupId, isEmailAlertActive));
  }

  handleModalClose() {
    this.setState({
      showModal: false
    });
  }

  render() {
    const {id, firstname, lastname, userNumber, email, description, profession, city, showModal, modalTitle, modalContent, image, registerRequired, isEmailAlertActive} = this.state;
    const {professions, history, user, selectedUser, messages} = this.props;

    return(
      <main className="profile">
        <Modal show={showModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100"><i className="fas fa-3x fa-envelope red" /></Modal.Title>
          </Modal.Header>
          <div className="text-center"><b>{modalTitle}</b></div>
          <Modal.Body className="text-center">{modalContent}</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            {registerRequired && !user &&
              <>
                <Button onClick={() => history.push(RoutePaths.LOGIN)} variant="primary">
                  Giriş Yap
                </Button>
              </>
            }

            {!registerRequired &&
              <Button onClick={() => this.handleModalClose()} variant="outline-primary">
                Tamam
              </Button>
            }

          </Modal.Footer>
        </Modal>
        <Row className="justify-content-center">
          <Col sm={12} md={4}>
            <ProfileCard selectedUser={selectedUser} user={user} history={history} userId={id} messages={messages} image={image} isMyProfile={this.isMe} handleImageChange={this.handleImageChange} clickMessageHandler={this.clickMessageHandler} blockUserHandler={this.blockUserHandler}/>
          </Col>
          <Col sm={12} md={4}>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group>
                <Form.Label>Ad: </Form.Label>
                <Form.Control disabled={true} onChange={this.handleInputChange} name="firstname" type="text" value={firstname}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Soyad: </Form.Label>
                <Form.Control disabled={true} onChange={this.handleInputChange} name="lastname" type="text" value={lastname}/>
              </Form.Group>
              {this.isMe === true &&
              <React.Fragment>
                <Form.Group>
                  <Form.Label>Bizden No: </Form.Label>
                  <Form.Control disabled={true} onChange={this.handleInputChange} type="text" value={userNumber}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>E-mail: </Form.Label>
                  <Form.Control disabled={true} onChange={this.handleInputChange} type="email" name="email" value={email}/>
                </Form.Group>
              </React.Fragment>
              }
              <Form.Group>
                <Form.Label>Şehir: </Form.Label>
                {city.value !== "" && <Form.Control disabled={true} value={city.label}/>}

                {this.isMe &&
                <AsyncSelect
                  isDisabled={!this.isMe}
                  theme={(theme) => this.reactSelectTheme(theme)}
                  loadingMessage={()=>("Aranıyor...")}
                  noOptionsMessage={()=>("Lütfen aramak istediğiniz şehri yazınız.")}
                  loadOptions={this.cityLoadOptions}
                  onChange={this.handleCityChange}
                  placeholder="Şehir değiştir"
                />
                }

              </Form.Group>
              <Form.Group>
                <Form.Label>Meslek: </Form.Label>
                {profession.value !== "" && <Form.Control disabled={true} name="za" value={profession.label}/>}
                {this.isMe &&
                <Select
                  isDisabled={!this.isMe}
                  theme={(theme) => this.reactSelectTheme(theme)}
                  loadingMessage={() => ("Aranıyor...")}
                  noOptionsMessage={() => ("Lütfen aramak istediğiniz mesleği yazınız.")}
                  onChange={this.handleProfessionChange}
                  options={professions}
                  filterOption={customProfessionFilter}
                  placeholder="Meslek değiştir"
                />
                }

              </Form.Group>
              <Form.Group>
                <Form.Label>Açıklama: </Form.Label>
                <Form.Control className="desc-area" disabled={!this.isMe} onChange={this.handleInputChange} name="description" as="textarea" rows="3" value={description}/>
              </Form.Group>
              {this.isMe === true &&
                <>
                  <Form.Group>
                    <Form.Check type="checkbox" label="E-mail bildirimlerini almak istiyorum." name="isEmailAlertActive" onChange={this.handleCheckBoxChange} checked={isEmailAlertActive}/>
                  </Form.Group>
                  <Form.Group>
                    <Button variant="primary btn-lg" type="submit">
                      KAYDET
                    </Button>
                  </Form.Group>
                </>
              }
            </Form>
          </Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = state => {

  const {rootReducer: {user, selectedUser, professions, updateUserSuccess, messages}} = state;

  return {
    user,
    selectedUser,
    professions,
    updateUserSuccess,
    messages
  };

};

export default connect(mapStateToProps)(withRouter(Profile));

import React, {Component} from "react";
import {Row, Col, Button, Form} from "react-bootstrap";
import {connect} from "react-redux";

import "./ForgetPassword.scss";
import actions from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";

class ForgetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      showModal: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const {dispatch} = this.props;
    const {email} = this.state;

    dispatch(actions.forgetPassword(email));

    this.setState({
      showModal: true
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false
    });
  }

  render() {
    const {email, showModal} = this.state;

    return(
      <React.Fragment>
        <Modal show={showModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100"><i className="fas fa-3x fa-envelope red" /></Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">Şifren email adresine gönderildi. Lütfen posta kutunu kontrol et.</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button onClick={() => this.handleModalClose()} variant="outline-primary">
              Tamam
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-center">
          <Col sm={12} md={4}>
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control name="email" value={email} onChange={this.handleInputChange} type="email" placeholder="Email Adresin" />
              </Form.Group>
              <Form.Group>
                <Button variant="primary btn-lg" type="submit">
                  GÖNDER
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect()(ForgetPassword);

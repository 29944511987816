import * as React from "react";
import {Fragment} from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import actions from "../../../redux/actions";

import "./Quotes.scss";


function Quotes(props) {
  const {quotes, quoteIndex, dispatch} = props;

  function forwardClick() {
    dispatch(actions.nextQuote());
  }

  function backwardClick() {
    dispatch(actions.previousQuote());
  }

  if (!quotes) {
    return <Fragment />
  }

  const quote = quotes[quoteIndex];

  return (
    <Row className="justify-content-center">
      <Col md={10}>
        <div className="quotes-container">
          <div className="navigation" onClick={backwardClick}>
            <i className="fas fa-2x fa-chevron-left"/>
          </div>
          <div className="content">
            <div className="quote">
              <div dangerouslySetInnerHTML={{ __html: quote.quote }} />
            </div>
            <div className="bottom-outer">
              <div className="bottom-inner">
                <div className="d-none">
                  <i className="mr-2 fas fa-briefcase"/>
                </div>
                <div className="d-none">
                  <i className="mr-2 fas fa-map-marker-alt"/>
                </div>
              </div>
            </div>
          </div>
          <div className="navigation" onClick={forwardClick}>
            <i className="fas fa-2x fa-chevron-right"/>
          </div>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = state => {

  const {rootReducer: {user, quotes, quoteIndex}} = state;

  return {
    user,
    quotes,
    quoteIndex,
  };
};

export default connect(mapStateToProps)(Quotes);

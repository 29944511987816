import React, {Fragment} from "react";

import "./LoadingIndicator.scss";

export default function LoadingIndicator() {
  return (
    <div className="loading-indicator">
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
}

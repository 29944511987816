import React, {Component}  from "react";
import {Row, Col, Button, Form} from "react-bootstrap";

import "./Login.scss";
import {Link} from "react-router-dom";
import RoutePaths from "../../../constants/RoutePaths";

import {connect} from "react-redux";
import actions from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      showModal: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {history} = this.props;

    if(nextProps.loginSuccess) {
      let newRegister = localStorage.getItem("newRegister") === "true";
      let newRegisterUserId = localStorage.getItem("newRegisterUserId");

      if (newRegister && newRegisterUserId) {
        history.push(RoutePaths.PROFILE.replace(":id", newRegisterUserId));
        localStorage.setItem("newRegister", null);
        localStorage.setItem("newRegisterUserId", null);
      } else {
        history.push(RoutePaths.HOME);
      }

    } else if(nextProps.loginError) {
      this.setState({
        showModal: true
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    const {dispatch} = this.props;
    const {email, password} = this.state;

    event.preventDefault();

    dispatch(actions.postLogin(email, password));
  }

  handleModalClose() {
    this.setState({
      showModal: false
    });
  }

  render() {
    return (
      <React.Fragment>
        <Modal show={this.state.showModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100"><i className="fas fa-3x fa-envelope red" /></Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">Email veya şifren yanlış.</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button onClick={() => this.handleModalClose()} variant="outline-primary">
              Tamam
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-center">
          <Col sm={12} md={8}>
            <div className="login-top-nav">
              <Link to={RoutePaths.SIGN_UP}>KAYIT OL</Link>
              <Link className="active" to={RoutePaths.LOGIN}>GİRİŞ YAP</Link>
            </div>
            <div className="login-card">
              <Form onSubmit={this.handleSubmit}>
                <Form.Group>
                  <Form.Label>E-mail: </Form.Label>
                  <Form.Control type="email" name="email" placeholder="E-mail" value={this.state.email} onChange={this.handleChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Şifre: </Form.Label>
                  <Form.Control type="password" name="password" placeholder="Şifre" value={this.state.password} onChange={this.handleChange}/>
                </Form.Group>
                <Form.Group>
                  <Link className="login-link-button" to={RoutePaths.FORGET_PASSWORD}>Şifremi unuttum</Link>
                </Form.Group>
                <Button variant="primary btn-lg mr-4" type="submit">
                  GİRİŞ YAP
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {

  const {rootReducer: {loginSuccess, loginError}} = state;

  return {
    loginSuccess,
    loginError
  };
};

export default connect(mapStateToProps)(Login);

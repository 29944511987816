import * as React from "react";
import { Col, Row} from "react-bootstrap";

import "./ConfirmDelete.scss";
import RoutePaths from "../../../constants/RoutePaths";

function ConfirmDelete() {

  return (
    <main className="ConfirmDelete">
      <Row className="justify-content-center">
        <Col sm={12} md={7}>
          <h1 className="help-title mb-4" >Son bir adım…</h1>
          <p className="text-center">Üyeliğini silme talebin email adresine yollandı. Emailine girip onay vermen durumunda üyeliğin tamamen silinecektir.</p>
          <p className="text-center">Yeniden aramızda olman dileği ile…</p>
          <div className="text-center mt-5">
            <i className="fas fa-question-circle" />
            <span>Sorun mu var? Bize <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a> adresinden ulaşabilirsin.</span>
          </div>
        </Col>
      </Row>
    </main>
  );
}

export default ConfirmDelete;

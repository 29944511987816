import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import Api from "../services/api";
import {ActionTypes} from "../redux/actions";
import actions from "../redux/actions";
import {RoutePaths} from "../constants";

function* login(action) {
  try {
    const {email, password} = action.payload;

    const authResult = yield call(Api.login, email, password);
    yield put(actions.getMessages());

    yield put(actions.postLoginFulfilled(authResult));
  } catch (e) {
    yield put(actions.postLoginFailed(e));
  }
}

function* logout() {
  try {
    yield call(Api.logout);
    yield put(actions.postLogoutFulfilled());
  } catch (e) {
    yield put(actions.postLogoutFailed(e));
  }
}

function* reAuthenticate() {
  try {
    const authResult = yield call(Api.reAuthenticate);

    yield put(actions.getMessages());

    yield put(actions.reAuthenticateFulfilled(authResult));
  } catch (e) {
    yield put(actions.reAuthenticateFailed(e));
  }
}

function* getCitySuggestions(action) {
  try {
    const {query} = action.payload;
    const cities = yield call(Api.getCitiesAndCountriesByQuery, query);
    console.log("-> cities", cities);

    yield put(actions.getCitySuggestionsFulfilled(cities));
  } catch (e) {
    yield put(actions.getCitySuggestionsFailed(e));
  }
}

function* register(action) {
  try {
    const {firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId} = action.payload;

    const authResult = yield call(Api.register, firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId);

    yield put(actions.postRegisterFulfilled(authResult));
  } catch (e) {
    yield put(actions.postRegisterFailed(e));
  }
}

function* getRelatedUsers(action) {
  try {
    const {cityId, countryId, professionId, professionGroupId} = action.payload;

    const result = yield call(Api.getUsers, cityId, countryId, professionId, professionGroupId, null, 100);

    yield put(actions.getRelatedUsersFulfilled(result));
  } catch (e) {
    yield put(actions.getRelatedUsersFailed(e));
  }
}

function* getUsers(action) {
  try {
    const {cityId, countryId, professionId, skip} = action.payload;

    const result = yield call(Api.getUsers, cityId, countryId, professionId, null, skip);

    yield put(actions.getUsersFulfilled(result));


  } catch (e) {
    yield put(actions.getUsersFailed(e));
  }
}

function* getUser(action) {
  try {
    const {id} = action.payload;

    const user = yield call(Api.getUser, id);

    yield put(actions.getUserFulfilled(user));
  } catch (e) {
    yield put(actions.getUserFailed(e));
  }
}

function* forgetPassword(action) {
  try {
    const {email} = action.payload;

    const authResult = yield call(Api.resetPassword, email);

    yield put(actions.forgetPasswordFulfilled(authResult));
  } catch (e) {
    yield put(actions.forgetPasswordFailed(e));
  }
}

function* changePassword(action) {
  try {
    const {email, oldPassword, password} = action.payload;

    yield call(Api.changePassword, email, oldPassword, password);

    yield put(actions.changePasswordFulfilled());
  } catch (event) {
    yield put(actions.changePasswordFailed(event));
  }
}

function* getTexts() {
  try {
    const texts = yield call(Api.getTexts);

    yield put(actions.getTextsFulfilled(texts));
  } catch (e) {
    yield put(actions.getTextsFailed(e));
  }
}

function* getProfessions() {
  try {
    yield put(actions.getTexts());

    const professions = yield call(Api.getProfessions);

    yield put(actions.getProfessionsFulfilled(professions));
  } catch (e) {
    yield put(actions.getProfessionsFailed(e));
  }
}

function* updateUser(action) {
  try {
    const {id, firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId, isEmailAlertActive} = action.payload;

    const user = yield call(Api.updateUser, id, firstname, lastname, email, password, cityId, professionId, description, isVisible, image, professionGroupId, isEmailAlertActive);

    yield put(actions.updateUserFulfilled(user));
  } catch (e) {
    yield put(actions.updateUserFailed(e));
  }
}

function routeProfile(action) {
  const {history} = action.payload;
  history.push(RoutePaths.PROFILE);
}

function* getMessages(action) {
  try {
    const messages = yield call(Api.getMessages);
    yield put(actions.getMessagesFulfilled(messages, action.payload.clearEmpty));
  } catch (e) {
    yield put(actions.getMessagesFailed(e));
  }
}

function* getMessagesOfUser(action) {
  try {
    const messages = yield call(Api.getMessagesOfUser, action.payload.uid);
    yield put(actions.getMessagesOfUserFulfilled(messages, action.payload.uid));
  } catch (e) {
    yield put(actions.getMessagesOfUserFailed(e));
  }
}

function* sendMessage(action) {
  try {
    yield call(Api.sendMessage, action.payload.message, action.payload.toUserId);
  } catch (e) {}
}

function* patchMessage(action) {
  try {
    yield call(Api.patchMessage, action.payload.message, action.payload.userId);
  } catch (e) {}
}

function* deleteMessages(action) {
  try {
    yield call(Api.deleteMessages, action.payload.userIds);

    const messages = yield call(Api.getMessages);
    yield put(actions.getMessagesFulfilled(messages, true));
  } catch (e) {
    yield put(actions.getMessagesFailed(e));
  }
}

function* postReport(action) {
  try {
    const {userId, values} = action.payload;

    const result = yield call(Api.postReport, userId, values);

    yield put(actions.postReportFulfilled(result));
  } catch (e) {
    yield put(actions.postReportFailed(e));
  }
}

function* blockUser(action) {
  try {
    const {userId} = action.payload;

    const result = yield call(Api.blockUser, userId);

    yield put(actions.blockUserFulfilled(result));
  } catch (e) {
    yield put(actions.blockUserFailed(e));
  }
}

function* unBlockUser(action) {
  try {
    const {userId} = action.payload;

    const result = yield call(Api.unBlockUser, userId);

    yield put(actions.unBlockUserFulfilled(result));
  } catch (e) {
    yield put(actions.unBlockUserFailed(e));
  }
}

function* rootSaga() {
  yield takeEvery(ActionTypes.POST_LOGIN, login);
  yield takeEvery(ActionTypes.POST_LOGOUT, logout);
  yield takeEvery(ActionTypes.RE_AUTHENTICATE, reAuthenticate);
  yield takeLatest(ActionTypes.GET_CITY_SUGGESTIONS, getCitySuggestions);
  yield takeLatest(ActionTypes.POST_REGISTER, register);
  yield takeEvery(ActionTypes.GET_USERS, getUsers);
  yield takeEvery(ActionTypes.GET_RELATED_USERS, getRelatedUsers);
  yield takeEvery(ActionTypes.GET_USER, getUser);
  yield takeEvery(ActionTypes.FORGET_PASSWORD, forgetPassword);
  yield takeEvery(ActionTypes.CHANGE_PASSWORD, changePassword);
  yield takeEvery(ActionTypes.GET_PROFESSIONS, getProfessions);
  yield takeEvery(ActionTypes.UPDATE_USER, updateUser);
  yield takeEvery(ActionTypes.ROUTE_PROFILE, routeProfile);
  yield takeEvery(ActionTypes.GET_MESSAGES, getMessages);
  yield takeEvery(ActionTypes.GET_MESSAGES_OF_USER, getMessagesOfUser);
  yield takeEvery(ActionTypes.SEND_MESSAGE, sendMessage);
  yield takeEvery(ActionTypes.PATCH_MESSAGE, patchMessage);
  yield takeEvery(ActionTypes.DELETE_MESSAGES, deleteMessages);
  yield takeEvery(ActionTypes.POST_REPORT, postReport);
  yield takeEvery(ActionTypes.BLOCK_USER, blockUser);
  yield takeEvery(ActionTypes.UNBLOCK_USER, unBlockUser);
  yield takeEvery(ActionTypes.GET_TEXTS, getTexts);
}

export default rootSaga;

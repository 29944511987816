import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import ReactPlayer from 'react-player';

import documentIcon from "../../../assets/images/document-icon.svg";
import messagingUtils from "../../../utils/messagingUtils";

import "./ChatMessageListItem.scss";

ChatMessageListItem.propTypes = {
  date: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  sentByMe: PropTypes.bool,
};

export default function ChatMessageListItem({date, message, image, sentByMe = false}) {
  let messageDate = moment(date);

  let today = moment();

  let time;

  if (today.isSame(messageDate, "d")) {
    time = moment(date).format("HH:mm");
  } else {
    time = moment(date).format("DD-MM-YYYY");
  }

  const containerClassNames = classnames({
    "chat-message": !sentByMe,
    "chat-message-me": sentByMe,
  });

  const videoPlayerStyle = {
    maxWidth: "300px"
  };

  let messageContent;
  if (message && message.indexOf("file_path") > 0) {
    const fileObject = JSON.parse(message);
    //console.log("-> fileObject", fileObject);
    if (fileObject.file_path.indexOf("jpg") > 0) {
      messageContent = <div className="chat-message">
        <a href={fileObject.file_path} target="_blank"><img className="chat-file-image" alt={fileObject.file_name} src={fileObject.thumbnail_path}/></a>
      </div>;
    } else if (fileObject.file_path.indexOf("mp4") > 0) {
      messageContent = <div className="chat-message">
        <ReactPlayer style={videoPlayerStyle} width="100%" height="auto" controls url={fileObject.file_path} />
      </div>;
    } else {
      messageContent = <div className="chat-message file">
        <a href={fileObject.file_path} target="_blank">
          <img className="chat-file-document" alt={fileObject.file_name} src={documentIcon}/>
        </a>
        <a className="link" href={fileObject.file_path} target="_blank">
          {fileObject.original_name}
        </a>
      </div>;
    }

  } else {
    messageContent = <div className="chat-message" dangerouslySetInnerHTML={{__html: messagingUtils.formatMessage(message).split('\n').join('<br/>')}} />;
  }

  return (
    <div className={containerClassNames}>
      {messageContent}
      <div className="chat-time">{time}</div>
    </div>
  );
}

import React, {Component, Fragment} from "react";
import {Row, Col, Button} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {connect} from "react-redux";

import Quotes from "../../parts/quotes/Quotes";
import actions from "../../../redux/actions";

import Logo from "../../../assets/images/logo-red.svg";
import LogoTitle from "../../../assets/images/logo-title-3.svg";
import "./Home.scss";
import SearchResults from "../../parts/searchResults/SearchResults";
import api from "../../../services/api";
import {customProfessionFilter} from "../../../utils/stringUtils";

class Home extends Component  {

  constructor(props) {
    super(props);

    this.state = {
      professionGroupId: null,
      professionId: null,
      cityId: null,
      citySelect: null,
      currentPage: 0
    };

    this.handleProfessionChange = this.handleProfessionChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.resetSearchHandler = this.resetSearchHandler.bind(this);
  }

  componentDidMount() {
    const {dispatch, pageRefresh} = this.props;

    if (!pageRefresh) {
      localStorage.removeItem('homeSavedState');
      this.setState({
        professionGroupId: null,
        professionId: null,
        cityId: null,
        citySelect: null,
        currentPage: 0
      });
    }

    dispatch(actions.pageRefresh(true));
  }

  componentWillUnmount() {
    localStorage.setItem('homeSavedState', JSON.stringify(this.state));
  }

  componentWillMount() {
    const rehydrate = JSON.parse(localStorage.getItem('homeSavedState'));
    this.setState(rehydrate);
  }

  cityLoadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 2) {
      if (inputValue.length > 2) {
        api.getCitiesAndCountriesByQuery(inputValue).then((suggestions) => {
          const cities = suggestions.cities.map((value) => {
            return {
              city_id: value.id,
              label: value.name_tr + " / " + value.country.name
            };
          });

          const countries = suggestions.countries.map((value) => {
            return {
              label: value.name,
              country_id: value.id
            };
          });

          const array = [...countries, ...cities];
          callback(array);
        });

      }
    }
  };

  reactSelectTheme(theme) {
    return(
      {
        ...theme,
        colors: {
          ...theme.colors,
          primary50: "#f8f9fa",
          primary25: "#f8f9fa",
          primary: "#ff2a48",
        },
      }
    );
  }

  handleCityChange(option, action){
    if (action.action === "clear") {
      this.setState({
        cityId: null,
        countryId: null,
        citySelect: null
      });
    }

    if (action.action === "select-option") {
      const {city_id, country_id} = option;

      let state = {
        citySelect: option
      };

      if (city_id) {
        state.cityId = city_id;
      }

      if (country_id) {
        state.countryId = country_id;
      }

      this.setState(state);
    }
  }

  handleProfessionChange(option, action){
    if (action.action === "clear") {
      this.setState({
        professionId: null,
        professionGroupId: null
      });
    }

    if(action.action === "select-option") {
      const {value, groupId} = option;

      this.setState({
        professionId: value,
        professionGroupId: groupId
      });
    }
  }

  handleGetUsers(usersSkip) {
    const {cityId, countryId, professionId, professionGroupId} = this.state;

    const {dispatch} = this.props;

    dispatch(actions.resetRelatedUsers());

    if(professionId || cityId || countryId) {
      dispatch(actions.getUsers(cityId, countryId, professionId, usersSkip));
    }

    if(professionId) {
      dispatch(actions.getRelatedUsers(cityId, countryId, professionId, professionGroupId));
    }
  }

  handlePreviousUsers() {
    const {usersTotal, usersLimit, usersSkip} = this.props;

    if (this.state.currentPage > 0) {
      const skip = (this.state.currentPage - 1) * usersSkip;
      this.handleGetUsers(skip);
    }
  }

  handleNextUsers() {
    const {usersTotal, usersLimit, usersSkip} = this.props;
    const totalPages = Math.ceil(usersTotal / usersLimit);

    if (this.state.currentPage < totalPages - 1) {
      const skip = (this.state.currentPage + 1) * usersLimit;
      this.handleGetUsers(skip);
    }
  }

  resetSearchHandler() {
    const {dispatch} = this.props;

    localStorage.removeItem('homeSavedState');

    dispatch(actions.resetHomeScreen());

    this.setState({
      professionId: null,
      citySelect: null,
      professionGroupId: null,
      cityId: null,
      currentPage: 0
    });
  }

  render() {
    const {users, relatedUsers, getRelatedUsersPending, getRelatedUsersSuccess, professions, history, title, usersTotal, usersLimit, usersSkip} = this.props;
    const {citySelect, professionId} = this.state;
    const selectedProfession = professionId && professions ?  professions.find((item) => item.value === professionId ) : null;

    let resultArea = [];

    if (!users && !relatedUsers) {
      resultArea.push(<Quotes key="1"/>);
    }

    if (users && users.length === 0 && !getRelatedUsersPending) {

      resultArea.push(
        <div className="Welcome" key="2">
          <Row className="justify-content-center mt-5">
            <Col sm={12} md={7}>
              <h1 className="help-title mb-4" >Şimdilik Kimse Yok</h1>
              <p className="text-center">Üzgünüm ama aradığın kişiler henüz üye olmamışlar. <br />  Az önce haberleri olmuş olabilir. İnan ki bu aralar, sosyal medyadan doğru şekilde tanıtmaya ciddi önem veriyoruz. Yakın zamanda tekrardan denemen iyi olabilir.</p>
              <div className="text-center mt-5">
                <i className="fas fa-question-circle" />
                <span>Sorun mu var? Bize <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a> adresinden ulaşabilirsin.</span>
              </div>
            </Col>
          </Row>
        </div>
      );

      if (getRelatedUsersSuccess && relatedUsers && relatedUsers.length > 0) {
        resultArea = [];
      }
    }

    if ((users && users.length > 0) || (relatedUsers && relatedUsers.length > 0)) {
      resultArea.push(<SearchResults users={users} history={history} key="3"/>);
    }

    let relatedResultArea;
    if (relatedUsers && relatedUsers.length > 0) {
      relatedResultArea = <SearchResults className="mt-5" isRelated={true} users={relatedUsers} history={history} key="4"/>;
    }

    let links = [];

    const pages = Math.ceil(usersTotal / usersLimit);

    this.state.currentPage = usersSkip / usersLimit;

    for (let index = 0; index < pages; index++) {

      if (this.state.currentPage === index) {
        links.push(
          <li key={index} className="page-item disabled"><a className="page-link" href="#" tabIndex="-1" key={"k" + index}>{index + 1}</a></li>
        );
      } else {

        const skip = index * usersLimit;

        links.push(
          <li className="page-item"><a className="page-link" href="#" tabIndex="-1" onClick={() => {this.handleGetUsers(skip);}} key={"kk" + index}>{index + 1}</a></li>
        );
      }
    }

    return (
      <main className="Home">
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-center align-items-center flex-wrap flex-column flex-md-row align-items-md-end logo-outer" onClick={this.resetSearchHandler}>
              <img alt="Logo" src={Logo} onClick={this.resetSearchHandler}/>
              <img className="logo-title" alt="Logo Title" src={LogoTitle}/>
            </div>
          </Col>
          <Col>
            <div className="mt-5 d-flex justify-content-center">
              <div className="explain text-center">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 mt-md-0">
          <Col md={6} className="mb-3 mt-md-5">
            <AsyncSelect
              theme={(theme) => this.reactSelectTheme(theme)}
              loadingMessage={()=>("Aranıyor...")}
              noOptionsMessage={()=>("Lütfen aramak istediğiniz şehri veya ülkeyi yazınız.")}
              loadOptions={this.cityLoadOptions}
              onChange={this.handleCityChange}
              placeholder="Bu şehirde veya ülkede..."
              isClearable={true}
              value={citySelect}
            />
          </Col>
          <Col md={6} className="mb-3 mt-md-5">
            <Select
              theme={(theme) => this.reactSelectTheme(theme)}
              loadingMessage={()=>("Aranıyor...")}
              noOptionsMessage={()=>("Lütfen aramak istediğiniz mesleği yazınız.")}
              onChange={this.handleProfessionChange}
              options={professions}
              filterOption={customProfessionFilter}
              ignoreAccent={false}
              value={selectedProfession}
              isClearable={true}
              placeholder="Bu meslekte..."/>
          </Col>
          <Col md={12}>
            <div className="d-flex justify-content-center mt-4 mt-md-5 mb-5">
              <Button onClick={() => this.handleGetUsers()} variant="primary btn-lg">BİZDEN KİM VAR?</Button>
            </div>
          </Col>
        </Row>

        {resultArea}

        {
          links.length > 1 &&

          <Row>
            <Col>

              <nav className="pagination-outer">
                <ul className="pagination justify-content-center pagination-lg flex-wrap">

                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous" onClick={() => {this.handlePreviousUsers();}}>
                      <span aria-hidden="true"><i className="fas fa-chevron-left color-purple-brown"/></span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>

                  {links}

                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next" onClick={() => {this.handleNextUsers();}}>
                      <span aria-hidden="true"><i className="fas fa-chevron-right color-purple-brown"/></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>

                </ul>
              </nav>

            </Col>
          </Row>
        }

        {relatedResultArea}
      </main>
    );
  }
}

const mapStateToProps = state => {

  const {rootReducer: {citySuggestions, users, relatedUsers, getRelatedUsersSuccess, getRelatedUsersPending, getUsersPending, getUsersSuccess, getUsersError, professions, title, usersTotal, usersLimit, usersSkip, cityId, professionId, professionGroupId, pageRefresh}} = state;

  return {
    citySuggestions,
    getUsersPending,
    getUsersSuccess,
    getUsersError,
    users,
    title,
    relatedUsers,
    professions,
    getRelatedUsersSuccess,
    getRelatedUsersPending,
    usersTotal,
    usersLimit,
    usersSkip,
    cityId,
    professionId,
    professionGroupId,
    pageRefresh
  };
};

export default connect(mapStateToProps)(Home);

import * as React from "react";
import {Button, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import RoutePaths from "../../../constants/RoutePaths";
import Resizer from "react-image-file-resizer";
import Badge from "react-bootstrap/Badge";
import api from "../../../services/api";
import {Fragment} from "react";

function ProfileCard(props) {

  const {selectedUser, user, image, handleImageChange, isMyProfile, clickMessageHandler, blockUserHandler, messages, userId, history} = props;

  let inputElement = null;

  let profileStyle = image !== null ? { backgroundImage: `url(${image})` } : {};

  function handleProfileImageChange(event) {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {

      if(file) {
        Resizer.imageFileResizer(
          file,
          600,
          600,
          "JPEG",
          100,
          0,
          uri => {
            if(handleImageChange) {
              handleImageChange(uri);
            }
          },
          "base64"
        );
      }
    };

    reader.readAsDataURL(file);
  }

  function imageClickHandler() {
    if(isMyProfile) {
      inputElement.click();
    }
  }

  function handleDeleteImage() {
    //null -> siler
    //"" -> no change
    handleImageChange(null);
  }

  function deleteUserhandler() {
    api.deleteUser(userId);

    history.push(RoutePaths.CONFIRM_DELETE);
  }

  let badge;

  if (props.isMyProfile && messages && messages.statistics && messages.statistics.totalUnread) {
    let unreadCount = messages.statistics.totalUnread;
    badge = <span className="badge badge-pill badge-notify-button">{unreadCount}</span>;
  }

  return (
    <div className="profile-outer">
      <div className="profile-image-outer">
        {isMyProfile === true ?
          <div onClick={imageClickHandler} className="profile-image with-cursor" style={profileStyle} />
          :
          <div onClick={imageClickHandler} className="profile-image" style={profileStyle} />
        }
        {isMyProfile === true &&
        <div onClick={imageClickHandler} className="plus-icon">
          <i className="fas fa-plus"/>
        </div>
        }
      </div>

      <Form.Control ref={input => inputElement = input} className="d-none" type="file" name="profileImage" accept="image/*" onChange={(event) => handleProfileImageChange(event)}/>
      <Button variant="outline-secondary mt-2 position-relative" onClick={clickMessageHandler}><span className="mr-1">{props.isMyProfile === true ? "Mesajlarım" : "Mesaj Gönder" }</span><i className="fas fa-envelope brown"/>{badge}</Button>
      {isMyProfile === true ?
        <React.Fragment>
          <Link to="#" onClick={handleDeleteImage} className="link-button">Fotoğrafı sil</Link>
          <Link to={RoutePaths.CHANGE_PASSWORD} className="link-button">Şifremi değiştir</Link>
          <Link to={"#"} onClick={deleteUserhandler} className="link-button">Hesabımı Sil</Link>
        </React.Fragment>
        :
        <Fragment>
          <Link to={RoutePaths.REPORT.replace(":id", userId)} className="link-button">Profili şikayet et</Link>

          {user && selectedUser &&
          <Link to="#" onClick={blockUserHandler} className="link-button">{selectedUser.isBlocked ? "Engeli Kaldır" : "Kullanıcıyı Engelle"}</Link>}
        </Fragment>
      }

    </div>
  );
}

export default ProfileCard;

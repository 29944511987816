import * as React from "react";
import {Button, Col, Row} from "react-bootstrap";

import "./Welcome.scss";
import {RoutePaths} from "../../../constants";
import {Link} from "react-router-dom";

function Welcome() {
  localStorage.setItem("newRegister", "true");

  return (
    <main className="Welcome">
      <Row className="justify-content-center">
        <Col sm={12} md={9}>
          <h1 className="help-title mb-5" >Hoşgeldin!</h1>
          <p className="text-center">Yakın zamanda “iyi ki üye olmuşum” dersen, ne mutlu hepimize. 👍 <br /> İşlerinde kolaylıklar. Kullanıcı bilgilerini profilinden istediğin an güncelleyebilirsin.</p>
          <div className="text-center my-4"><Link to={RoutePaths.LOGIN}><Button variant="primary btn-lg ml-4">GİRİŞ YAP</Button></Link></div>
          <div className="text-center">
            <i className="fas fa-question-circle" />
            <span>Sorun mu var? Bize <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a> adresinden ulaşabilirsin.</span>
          </div>
        </Col>
      </Row>
    </main>
  );
}

export default Welcome;

import React from "react";
import {connect} from "react-redux";
import {Footer, Header} from "./components/parts";
import AppRoutes from "./AppRoutes";
import {Container as BootstrapContainer} from "react-bootstrap";
import smartBanner from "smartbanner.js";
import "smartbanner.js/dist/smartbanner.min.css";

// eslint-disable-next-line no-unused-vars
import twitter from "./assets/images/email-twitter.png";
// eslint-disable-next-line no-unused-vars
import instagram from "./assets/images/instagram.png";
// eslint-disable-next-line no-unused-vars
import logo from "./assets/images/logo.png";
// eslint-disable-next-line no-unused-vars
import logoWhite from "./assets/images/logo-white.png";
// eslint-disable-next-line no-unused-vars
import favicon1 from "./assets/images/favicon/favicon-16x16.png";
// eslint-disable-next-line no-unused-vars
import favicon2 from "./assets/images/favicon/favicon-32x32.png";
// eslint-disable-next-line no-unused-vars
import appIcon from "./assets/images/app-icon.png";

function Container({history, reAuthenticatePending, getTextsPending}) {
  if (reAuthenticatePending || getTextsPending) {
    return (
      <div></div>
    );
  }

  return (
    <React.Fragment>
      <BootstrapContainer>
        <Header history={history}/>
        <AppRoutes/>
      </BootstrapContainer>
      <Footer/>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: {
      reAuthenticatePending,
      getTextsPending
    }
  } = state;

  return {
    reAuthenticatePending,
    getTextsPending
  };
};

export default connect(mapStateToProps)(Container);

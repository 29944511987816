import React from "react";
import { Redirect, Route } from "react-router-dom";
import {connect} from "react-redux";

function PrivateRoute({ component: OriginalComponent, user, ...rest }) {
  const authenticated = user;

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <OriginalComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => {
  const {rootReducer: {user}} = state;

  return {
    user
  };
};

export default connect(mapStateToProps)(PrivateRoute);

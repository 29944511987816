import * as React from "react";
import { Col, Row} from "react-bootstrap";

import "./EmptySearch.scss";
import RoutePaths from "../../../constants/RoutePaths";

function EmptySearch() {

  return (
    <main className="Welcome">
      <Row className="justify-content-center">
        <Col sm={12} md={7}>
          <h1 className="help-title mb-4" >Başarısız Arama</h1>
          <p className="text-center">Üzgünüm ama aradığın kişiler henüz üye olmamışlar. <br />  Az önce haberleri olmuş olabilir. İnan ki bu aralar, sosyal medyadan doğru şekilde tanıtmaya ciddi önem veriyoruz. Yakın zamanda tekrardan denemen iyi olabilir.</p>
          <div className="text-center mt-5"><a href={RoutePaths.HOME} className="btn btn-primary">TEKRAR DENE</a></div>
          <div className="text-center mt-5">
            <i className="fas fa-question-circle" />
            <span>Sorun mu var? Bize <a href="mailto:destek@bizdenkimvar.com">destek@bizdenkimvar.com</a> adresinden ulaşabilirsin.</span>
          </div>
        </Col>
      </Row>
    </main>
  );
}

export default EmptySearch;

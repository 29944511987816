import * as React from "react";
import {Row, Col, Card, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

import "./SearchResults.scss";

import {RoutePaths} from "../../../constants";
import {connect} from "react-redux";

function SearchResults(props) {

  const {isRelated, users, user, history, usersTotal} = props;

  let content = [];
  let cols = [];

  if (users) {

    users.map((currUser) => {
      let profileStyle = currUser && currUser.image !== null ? { backgroundImage: `url(${currUser.image})` } : {};

      let route = "";

      if (user && user.id === currUser.id) {
        route = RoutePaths.ME;
      } else {
        route = RoutePaths.PROFILE.replace(":id", currUser.id);
      }

      cols.push(

        <Col xs={6} md={3} lg="auto" className="mt-3 profile-container" key={currUser.id}>
          <Link to={route}>
            <Card className="profile-outer">
              <div className="profile-image" style={profileStyle}/>
              {currUser.isOnline ? <div className={currUser.isOnline ? "online" : "offline"} /> : ""}
              <Card.Body>
                <Card.Title>{currUser.firstname} {currUser.lastname}</Card.Title>
                <Card.Text>
                  {currUser.profession && <><i className="mr-2 fas fa-briefcase"/>{currUser.profession.name}</> }
                </Card.Text>
                <Card.Text>
                  {currUser.city && <><i className="mr-2 fas fa-map-marker-alt"/>{currUser.city.name_tr}, {currUser.country.name}</> }
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button variant="outline-secondary btn-block"><span className="mr-1">Profile Git</span><i className="fas fa-envelope brown"/></Button>
              </Card.Footer>
            </Card>
          </Link>
        </Col>

      );

    });

    content.push(<Row key="1" className="justify-content-center">{cols}</Row>);
  }

  return (
    <React.Fragment>


      <Row className="mt-5">
        <Col md={12}>
          <div className="d-flex justify-content-center flex-wrap">
            {isRelated ?
              <p className="status-container">İlişkili mesleklerden bizden<span className="count mx-2">{users ? users.length : 0}</span>kişi var</p>
              :
              <p className="status-container">Bizden<span className="count mx-2">{usersTotal ? usersTotal : 0}</span>kişi var</p>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}><hr className="red"/></Col>
      </Row>

      {content}

    </React.Fragment>
  );
}

const mapStateToProps = state => {

  const {rootReducer: {user, usersTotal}} = state;

  return {
    user,
    usersTotal
  };

};

export default connect(mapStateToProps)(SearchResults);
